.mat-drawer-container {
  font-family: $font-primary;
  color: $base-color;
  background-color: transparent;

  &.countable-drawer {
    height: 100%;
    @include flex-combinator(row, nowrap);

    &.mat-drawer-transition {
      .mat-drawer-backdrop {
        display: none;
      }
    }


    .mat-drawer {
      position: initial;
      box-shadow: none;
      width: 300px;
      transition: 0.3s;
      @include flex-combinator(row, nowrap);
      border-right: $size-1 solid $gray-3;

      @media (max-width: 1366px) {
        width: 280px;
      }

      @media (max-width: 991px) {
        position: fixed;
        top: 50px;
        height: calc(100% - 50px);
      }

      @media (max-width: 991px) {
        position: fixed;
        top: 50px;
        height: calc(100% - 50px);

        &.drawer-from-right {
          right: 0;
        }
      }

      &[style*="visibility: hidden"] {
        @include flexbox;
        width: $size-24 !important;
        visibility: visible !important;
        transform: translate(0);
        transition: 0.3s;

        .toogle-button-block {
          &.button-block-left {
            .toogle-button {
              &:after {
                transform: rotate(-90deg);
                transition: 0.3s;
              }
            }
          }

          &.button-block-right {
            .toogle-button {
              &:after {
                transform: rotate(90deg);
                transition: 0.3s;
              }
            }
          }
        }

        @media (max-width: 1366px) {
          width: $size-20 !important;
        }

        @media (max-width: 991px) {
          width: 0px !important;
          overflow: visible;

          .toogle-button-block {
            .toogle-content {
              display: none;
            }
          }
        }

        div[style*=ew-resize] {
          pointer-events: none !important;
        }
      }

      .mat-drawer-inner-container {
        @include flex-combinator(row, nowrap);
        overflow: hidden;

        .content-block {
          height: 100%;
          @include flex-combinator(column, nowrap);
          overflow: auto;
          flex: 1;
        }

        @media (max-width: 991px) {
          overflow: visible;
        }
      }

      .toogle-content {
        @include flex-combinator(row, wrap);
        width: 100%;
        display: none;
        visibility: hidden;
        transition: 0.3s;
        transform: rotate(90deg);
      }

      &.prev-right-drawer {
        width: 100vw;

        &.mat-drawer-opened {
          .income-state-container-aside {

            income-sheet-preview,
            app-statement-of-retained-earning,
            notes-to-fs-preview {
              @include flex-combinator(column, nowrap);
              width: 100%;
              height: 100%;

              .workbook-preview {
                min-width: 500px;
                // overflow: visible;
                // position: initial;
              }
            }
          }
        }

        @media (min-width: 992px) {
          width: 600px;
          transform: initial !important;
        }

        @media (max-width:1440px) {
          position: absolute;
          right: 0;
          top: 0;
          transform: initial !important;
        }
      }
    }

    .mat-drawer-content {
      flex: 1;
      @include flex-combinator(row, nowrap, flex-start);
    }

    .toogle-button-block {
      background-color: $gray-1;
      height: 100%;
      width: $size-24;
      @include flex-combinator(column, nowrap, flex-start);

      .title {
        font-size: $size-12;
      }

      .toogle-button {
        background-image: none !important;
        transition: 0.3s;
        height: 30px;
        width: 18px;
        min-width: initial !important;

        &:after {
          content: "\f107";
          font-family: 'Font Awesome 6 Pro' !important;
          font-size: $size-14;
          @include sequre(100%);
          @include flex-combinator(column, nowrap, center, center);
        }
      }

      &.button-block-left {
        padding-right: $size-2;

        .toogle-button {
          &:after {
            transform: rotate(90deg);
            transition: 0.3s;
          }
        }
      }

      &.button-block-right {
        padding-left: $size-2;

        .toogle-button {
          &:after {
            transform: rotate(-90deg);
            transition: 0.3s;
          }
        }
      }

      // @media (max-width: 1366px) {
      //     width: $size-20;

      .title {
        font-size: $size-12;
      }
    }

    .toogle-button-block {
      @media (max-width: 991px) {
        width: 30px;
        position: absolute;
        height: 30px;
        left: 0;
        z-index: 999999;
        padding: 0;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);

        .toogle-button {
          width: 30px;
          height: 30px;
          border-radius: 0 !important;

          &:hover {
            background-color: $gray-3;
          }
        }

        .toogle-content {
          display: none;
        }
      }

      &.button-block-right {
        left: inherit;
        right: 0;
      }
    }
  }





  &.countable-drawer-new {
    height: 100%;
    @include flex-combinator(row, nowrap);

    &.mat-drawer-transition {
      .mat-drawer-backdrop {
        display: none;
      }
    }


    .mat-drawer {
      position: initial;
      box-shadow: none;
      transition: 0.3s !important;

      @media (max-width: 1366px) {
        width: 280px;
      }

      @media (max-width: 991px) {
        position: fixed;
        top: 50px;
        height: calc(100% - 50px);
      }

      @media (max-width: 991px) {
        position: fixed;
        top: 50px;
        height: calc(100% - 50px);

        &.drawer-from-right {
          right: 0;
        }
      }

      .mat-drawer-inner-container {
        @include flex-combinator(row, nowrap);
        overflow: hidden;

        .content-block {
          height: 100%;
          @include flex-combinator(column, nowrap);
          overflow: auto;
          flex: 1;
        }

        @media (max-width: 991px) {
          overflow: visible;
        }
      }

      &.prev-right-drawer {
        width: 100vw;

        &.mat-drawer-opened {
          .income-state-container-aside {

            income-sheet-preview,
            app-statement-of-retained-earning,
            notes-to-fs-preview {
              @include flex-combinator(column, nowrap);
              width: 100%;
              height: 100%;

              .workbook-preview {
                min-width: 500px;
                // overflow: visible;
                // position: initial;
              }
            }
          }
        }

        @media (min-width: 992px) {
          width: 600px;
          transform: initial !important;
        }

        @media (max-width:1440px) {
          position: absolute;
          right: 0;
          top: 0;
          transform: initial !important;
        }
      }
    }

    > .mat-drawer-content {
      > .toogle-button-block {
        .toogle-content {
          @include flex-combinator(row, wrap);
          width: 100%;
          visibility: visible;
          transition: 0.3s;
          transform: rotate(90deg);
        }
      }
    }



    .mat-drawer-content {
      flex: 1;
      @include flex-combinator(row, nowrap, flex-start);
    }

    .toogle-button-block {
      background-color: $gray-1;
      height: 100%;
      width: $size-24;
      @include flex-combinator(column, nowrap, flex-start);
      position: relative;

      .title {
        font-size: $size-12;
      }

      .toogle-button {
        background-image: none !important;
        transition: 0.3s;
        height: 30px;
        width: $size-24;
        min-width: initial !important;
        position: initial;

        &:after {
          content: "\f107";
          font-family: 'Font Awesome 6 Pro' !important;
          font-size: $size-14;
          @include sequre(100%);
          @include flex-combinator(column, nowrap, center, center);
        }
        &:before {
          content: '';
          @include sequre(100%);
          top: 0;
          left: 0;
          position: absolute;
        }
      }

      &.button-block-left {
        padding-right: $size-2;

        .toogle-button {
          &:after {
            transform: rotate(-90deg);
            transition: 0.3s;
          }
        }
      }

      &.button-block-right {
        padding-left: $size-2;

        .toogle-button {
          &:after {
            transform: rotate(90deg);
            transition: 0.3s;
          }
        }
      }

      .title {
        font-size: $size-12;
      }
    }

    .toogle-button-block {
      @media (max-width: 991px) {
        width: 30px;
        position: absolute;
        height: 30px;
        left: 0;
        z-index: 999999;
        padding: 0;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);

        .toogle-button {
          width: 30px;
          height: 30px;
          border-radius: 0 !important;

          &:hover {
            background-color: $gray-3;
          }
        }

        .toogle-content {
          display: none;
        }
      }

      &.button-block-right {
        left: inherit;
        right: 0;
      }
    }

    &.mat-drawer-container-has-open {
      .toogle-button-block {
        &.button-block-left {
          .toogle-button::after {
            transform: rotate(90deg);
          }
        }
        &.button-block-right {
          .toogle-button {
            &:after {
              transform: rotate(-90deg);
            }
          }
        }
      }



      > .mat-drawer-content {
        > .toogle-button-block {
          .toogle-content {
            display: none;
            visibility: hidden;
            transition: 0.3s;
          }
        }
      }



    }
  }





}


.mat-drawer-backdrop.mat-drawer-shown {
  display: none !important;
}

.right-corner-dialog {
  .countable-dialog {
    border-radius: 0 !important;
  }
}

.mat-drawer-container.countable-drawer {
  .mat-drawer.prev-right-drawer {
    &.components-preview-section {
      width: 800px;
    }
  }
}

body .vertical-tabs {
  .mat-mdc-tab-body-wrapper {
    min-width: 250px;
  }
}

.right-side-panel-hide,
.hide-right-panel,
.mat-drawer[style*="visibility: hidden"] {
  div[style*=ew-resize],
  div[style*=e-resize] {
    pointer-events: none !important;
    display: none;
  }
  .toogle-content {
    visibility: visible !important;
    display: flex !important;
  }
}
