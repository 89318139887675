%item-title {
  // @include flex-combinator(row, nowrap, center, flex-start);
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  max-width: 100%;
  color: $gray-9;
  font-size: $size-14;
  font-weight: $medium;
  font-family: $font-primary;
  text-decoration: none !important;
  text-align: left;
  cursor: pointer;
  img {
    max-width: $size-16;
    max-height: $size-16;
  }
}
.list-item-content {
  height: $size-40;
  padding: 0 $size-8;
  color: $gray-7;
  font-size: $size-14;
  font-weight: $medium;
  font-family: $font-primary;
  width: 100%;
  gap: $size-4;
  text-decoration: none !important;
  border-bottom: 1px solid $gray-2;
  cursor: pointer;
  display: flex !important;
  @include flex-combinator(row, nowrap, center, flex-start);

  &.mat-radio-button {
    .mat-radio-label {
      height: 100%;
      @include flex-combinator(row, nowrap, center, flex-start);
    }
  }

  .dragable-icon {
    opacity: 0;
    transition: 0.3s;
    &:empty {
      display: none;
    }
  }

  .item-title {
    @extend %item-title;
    margin-right: auto;
  }

  &:hover,
  &:focus {
    background-color: $primary-lighter;
    color: $primary-darker;
    .dragable-icon {
      opacity: 1;
      transition: 0.3s;
    }
  }
  &.active {
    background-color: $primary-lighter;
    color: $primary-darker;
    &:hover,
    &:focus {
      background-color: $primary-lighter;
      color: $primary-darker;
    }
    .item-title {
      color: $primary-darker;
    }
  }
  a {
    color: $gray-7;
    font-size: $size-14;
    font-weight: $medium;
    font-family: $font-primary;
    text-decoration: none !important;
  }

  img {
    max-width: $size-16;
    max-height: $size-16;
  }
  .kebab-menu-button,
  .kebab-button,
  .edit-icon {
    opacity: 0;
    padding: 0 $size-4;
  }

  &:hover,
  &:focus {
    background-color: $indigo-50;
    text-decoration: none;
    .kebab-menu-button,
    .kebab-button,
    .edit-icon {
      opacity: 1;
    }
  }

  .item-checkbox {
    height: $size-20;
    @include flexbox;
    .mat-checkbox-label {
      display: none;
    }
  }

  &.item-disabled {
    border-color: $gray-2 !important;
    background-color: $gray-2 !important;
    cursor: auto;
    &:hover {
      background-color: $gray-2;
    }
    > :not([class*="lock-button"]) {
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

.item-numanic {
  text-transform: uppercase;
  font-weight: $semibold;
  color: $purple-5;
  &:empty {
    display: none;
  }
}
.item-count {
  color: $primary-darker;
  &:empty {
    display: none;
  }
}
.path-title-block {
  @include flex-combinator(column, nowrap);
  flex: 1;
  max-width: 100%;
  overflow: hidden;
}
.item-file-path {
  line-height: 1;
  font-weight: $regular;
  font-size: $size-12;
  color: $gray-8;
  &:empty {
    display: none;
  }
}

.signOff-panel {
  .template-block-main {
    .mat-tab-group {
      .mat-tab-header {
        border-bottom: 0;
      }
    }
  }
  .title-content {
    border-bottom: 0 !important;
  }
}



.signOff-panel-wrapper {
  border-bottom: 1px solid $gray-3;
  @include flex-combinator(row, nowrap, initial, space-between);
  flex: 1;
}

.ntr-panel-left {
  overflow: initial !important;
  &.signOff-panel-enabled {
    &[style$="4"] {
      width: Initial !important;
    }
  }
}


.ntr-right-panel-desabled {
  .ntr-panel-right {
    margin-right: -300px;
    width: 350px !important;

    div[style*="ew-resize"] {
      pointer-events: none;
    }
  }
}
mat-drawer,
.ntr-panel-right {
  &[style*="0.2s"],
  &[style*="none"] {
    left: Initial !important;
    overflow: initial !important;
  }
}

div[style*="ew-resize"] {
  z-index: 2;

  &:hover {
    background: $primary-darker !important;
  }
  &:after,
  &:before {
    content: '';
    width: $size-4;
    position: absolute;
    background: transparent;
    top: 0;
    height: 100%;
  }
  &:after {
    right: -$size-4;
  }
  &:before {
    left: -$size-4;
  }
}

.template-block-main {
  height: 100%;
  width: 100%;
  overflow: auto;
  @include flex-combinator(column, nowrap, flex-start, flex-start);

  .mat-expansion-panel {
    border-radius: 0 !important;
    box-shadow: none;
    width: 100% !important;
    border: 0 !important;

    .mat-expansion-panel-content-wrapper {
      overflow: hidden;
    }
  }

  mat-tab-group,
  .mat-tab-group,
  .mat-mdc-tab-group {
    width: 100%;
    height: 100%;
    font-family: $font-primary;
    overflow: hidden;
    mat-tab-header,
    .mat-tab-header,
    .mat-mdc-tab-header {
      border-bottom: 1px solid $gray-3;
    }
  }

  .mat-tab-list, .mat-mdc-tab-list {
    .mat-tab-labels, .mat-mdc-tab-labels {
      @include justify-content(center);
      .mat-tab-label,
      .mdc-tab {
        padding: 0.625rem;
        min-width: auto;
        height: auto;
        color: $gray-5;
        font-size: $size-14;
        opacity: 1;
        font-family: $font-primary;
        width: 100%;

        &:hover {
          color: $primary;
        }

        &.mat-tab-label-active {
          color: $primary-darker;
        }
      }
    }
    .mat-ink-bar {
      background-color: $primary;
      // display: block !important;
    }
  }
  .mat-content {
    &.mat-content-hide-toggle {
      margin: 0;
    }
  }

  .mat-accordion {
    .mat-expansion-panel-header {
      height: auto;
      padding: 0;
      border: 0;
      font-family: $font-primary;

      .mat-expansion-panel-header-title {
        font-family: $font-primary;
        margin: 0;
        padding: 0;
      }

      .title-content,
      a.title-content {
        height: $size-40;
        padding: 0 $size-8;
        color: $gray-9;
        font-size: $size-14;
        font-weight: $semibold;
        font-family: $font-primary;
        width: 100%;
        gap: $size-4;
        border-bottom: $size-1 solid $gray-2;
        text-decoration: none !important;
        @include flex-combinator(row, nowrap, center, flex-start);

        .item-title {
          font-weight: $semibold;
        }
        &:hover,
        &:focus {
          background-color: $primary-lighter;
          color: $primary-darker;
        }
        &.active {
          background-color: $primary-lighter;
          color: $primary-darker;
          &:hover,
          &:focus {
            background-color: $primary-lighter;
            color: $primary-darker;
          }
          .item-title {
            color: $primary-darker;
          }
        }
        .item-checkbox {
          @include sequre($size-20);
        }
      }

      &.height-46 {
        height: 46px;
      }
      .kebab-menu-button,
      .kebab-button {
        opacity: 0;
        padding: 0 $size-4;
        min-width: 12px;
        @media only screen and (min-device-width: 1400px) and (max-device-width: 1600px){
          min-width: 30px !important;        }

      }

      &:hover {
        background-color: $indigo-50;
        .kebab-menu-button,
        .kebab-button {
          opacity: 1;
        }
      }

      .mat-expansion-indicator {
        display: none;
      }
      .togglable-icon {
        width: 12px;
        min-width: 12px;
        @include flex-combinator(row, nowrap, center, center);
          .toogle-icon {
            color: $gray-6;
            transition: 0.3s;
          }
      }

      &[aria-expanded="true"] {
        .togglable-icon {
          .toogle-icon {
            transform: rotate(90deg);
            transition: 0.3s;
          }
        }
      }

      &.mat-expanded {
        height: auto;
        &.height-46 {
          height: 46px;
        }
      }
      &:before {
        display: none !important;
      }
      &.no-children {
        .togglable-icon {
          .toogle-icon {
            display: none;
          }
        }
        .item-doc-action {
          flex: 1;
          height: 100%;
        }
      }
      &.active {
        .list-item-content {
          background-color: $primary;
          border-bottom-color: $primary;
          &:hover,
          &:focus,
          &:hover:not([aria-disabled="true"]) {
            background-color: $primary;
          }
          .item-title {
            color: $white;
          }
        }
      }

      .mat-expansion-panel-header-title {
        padding: 0;
        // @include flexbox;
        @include flex-combinator(row, wrap);
        width: 100%;
        .panel-title-inner {
          height: $size-40;
          padding: 0 0.5rem;
          border-bottom: $size-1 solid $gray-2;
          width: 100%;
          text-decoration: none !important;
          @include flex-combinator(row, nowrap, center, flex-start);
          &:hover {
            background-color: $indigo-50;
            .kebab-menu-button,
            .kebab-button {
              opacity: 1;
            }
          }
          &.active {
            background-color: $primary;
            border-bottom-color: $primary;
            color: $white;
            &:hover,
            &:focus,
            &:hover:not([aria-disabled="true"]) {
              background-color: $primary;
              color: $white;
            }
            .item-title {
              color: $white;
            }
          }
        }
      }
    }

    .mat-expansion-panel {
      mat-list.templates-list-items,
      .mat-list.templates-list-items,
      .mat-mdc-list.templates-list-items,
      ul.templates-list-items {
        li.list-item-content,
        .list-item-content {
          // padding-left: $size-28;
          padding-left: 20px;
        }
      }

      &.level-one,
      &.level-first {
        .mat-expansion-panel-header {
          .title-content {
            // padding-left: $size-28;
            padding-left: 20px;
          }
        }
        mat-list.templates-list-items,
        .mat-list.templates-list-items,
        .mat-mdc-list.templates-list-items,
        ul.templates-list-items {
          li.list-item-content,
          .list-item-content {
            padding-left: 50px;
            // padding-left: 36px;
          }
        }
      }
      &.level-two,
      &.level-second {
        .mat-expansion-panel-header {
          .title-content {
            // padding-left: 50px;
            padding-left: 36px;
          }
        }
        mat-list.templates-list-items,
        .mat-list.templates-list-items,
        .mat-mdc-list.templates-list-items,
        ul.templates-list-items {
          li.list-item-content,
          .list-item-content {
            // padding-left: 72px;
            padding-left: 52px;
          }
        }
      }
      &.level-three,
      &.level-third {
        .mat-expansion-panel-header {
          .title-content {
            // padding-left: 72px;
            padding-left: 52px;
          }
        }
        mat-list.templates-list-items,
        .mat-list.templates-list-items,
        .mat-mdc-list.templates-list-items,
        ul.templates-list-items {
          li.list-item-content,
          .list-item-content {
            // padding-left: 94px;
            padding-left: 86px;
          }
        }
      }
      &.level-four,
      &.level-fourth {
        .mat-expansion-panel-header {
          .title-content {
            // padding-left: 94px;
            padding-left: 70px;
          }
        }
        mat-list.templates-list-items,
        .mat-list.templates-list-items,
        .mat-mdc-list.templates-list-items,
        ul.templates-list-items {
          li.list-item-content,
          .list-item-content {
            // padding-left: 116px;
            padding-left: 102px;
          }
        }
      }
      &.level-five,
      &.level-fifth {
        .mat-expansion-panel-header {
          .title-content {
            // padding-left: 116px;
            padding-left: 88px;
          }
        }
        mat-list.templates-list-items,
        .mat-list.templates-list-items,
        .mat-mdc-list.templates-list-items,
        ul.templates-list-items {
          li.list-item-content,
          .list-item-content {
            padding-left: 106px;
            // padding-left: 256px;
          }
        }
      }
    }
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-tab-body-wrapper,
  .mat-mdc-tab-body-wrapper {
    flex: 1;
    mat-tab-body,
    .mat-tab-body,
    .mat-mdc-tab-body {
      padding-top: $size-20;

      .mat-tab-body-content,
      .mat-mdc-tab-body-content {
        height: 100%;
        overflow: hidden;
        gap: $size-20;
        @include flex-combinator(column, nowrap, flex-start, flex-start);

        .search-block {
          padding: 0 $size-8;
          width: 100%;
          @include flex-combinator(row, nowrap, center, center);
        }
        .collapse-all-button {
          padding: 0 $size-20;
        }

        .templates-list {
          // height: calc(100% - 42px);
          height: 100%;
          width: 100%;
          overflow-y: auto;
          border-top: 1px solid $gray-2;
          border-bottom: 1px solid $gray-2;
        }
      }
    }
  }
}

.dragable-icon {
  width: $size-10;
  cursor: grab;
}
.item-icon {
  @include flex-combinator(row, nowrap, center, center);
  @include sequre($size-16);
  i{
    color: $gray-5;
    font-size: $size-16;
  }
}
.item-num,
.item-numanic {
  color: $purple-5;
  text-transform: uppercase;
  font-weight: $semibold;
}
.extension-title {
  @include flex-combinator(row, nowrap, center, flex-start);
  flex: 1;
  overflow: hidden;
  .item-title {
    width: auto;
    max-width: 100%;
    flex: initial;
    margin-right: 0;
  }
  .item-extension {
    color: $gray-9;
    font-size: $size-14;
    font-weight: $medium;
  }
}

.item-title {
  @extend %item-title;
}
.set-default-dot {
  @include circle($size-12);
  background-color: $success;
  // display: none;
}
// .default-template {
//     .set-default-dot {
//         @include inline-flex;
//     }
// }
.lock-icon {
  display: none;
  margin-left: auto;
}
.template-locked {
  // background-color: $success-1;
  // pointer-events: none;
  .lock-icon {
    @include inline-flex;
  }
}

mat-list.templates-list-items,
.mat-list.templates-list-items,
.mat-mdc-list.templates-list-items,
ul.templates-list-items {
  padding: 0;
  font-family: $font-primary;
  margin: 0;
  @include flex-combinator(column, nowrap);

  mat-list-item,
  .mat-list-item,
  .mat-mdc-list-item,
  .mdc-list-item {
    @include flex-combinator(row, nowrap);
    height: auto;
    border-width: 0;
    font-family: $font-primary;
    .mat-list-item-content {
      padding: 0;
      width: 100%;
    }
  }
  &.parent-list {
    mat-list-item,
    .mat-list-item,
    .mat-mdc-list-item {
      .template-child-title {
        &.active {
          background-color: $primary;
          color: $white;
          &:hover,
          &:focus {
            background-color: $primary;
            color: $white;
          }
          .item-title {
            color: $white;
          }
        }
      }
    }
  }
  a {
    cursor: pointer;
  }

  .item-label {
    @include flex-combinator(column, nowrap);
    width: 100%;
  }
}

.no-data-found {
  @include flex-combinator(column, nowrap, center, center);
  width: 500px;
  max-width: 100%;
  height: 100%;
  gap: $size-16;
  margin: 0 auto;
  text-align: center;
  padding: $size-24;
  .text-link {
    font-weight: $semibold;
  }
}

.search-filter-block {
  @include flex-combinator(row, nowrap, center, flex-end);
  gap: $size-4;
  width: 100%;
  position: relative;
}

.collapsable-search-block {
  @include flexbox;
  .search-toogle-button {
    .close-img {
      display: none;
    }
  }
  &.field-expanded {
    .search-toogle-button {
      .close-img {
        display: block;
      }
      .expand-img {
        display: none;
      }
    }
  }

  .search-box {
    position: absolute;
    right: 40px;
    top: $size-2;
    display: none;
    background-color: #ffffff;

    &.search-box-enabled {
      display: block;
    }
  }
}

.mat-expansion-panel {
  .mat-expansion-panel-content {
    opacity: 0;
  }
  &.mat-expanded {
    .mat-expansion-panel-content {
      opacity: 1;
    }
  }
}
.mat-expansion-panel-content {
  &[style*="visibility: hidden;"] {
    overflow: hidden !important;
  }
}

.template-block-main {
  &.right-drawer-template-block {
    .mat-tab-body-wrapper,
    .mat-mdc-tab-body-wrapper {
      mat-tab-body,
      .mat-tab-body,
      .mat-mdc-tab-body {
        padding-top: 0;
        .mat-tab-body-content,
        .mat-mdc-tab-body-content {
          gap: 0;
        }
      }
    }
  }
}

.item-doc-action {
  @include flex-combinator(row, nowrap, center);
  margin-right: auto;
  max-width: 100%;
  overflow: hidden;
  gap: $size-4;
}


.templates {
  .template-block-main {
    .mat-tab-list,
    .mat-mdc-tab-list {
      .mat-tab-labels,
      .mat-mdc-tab-labels {
        .mat-tab-label,
        .mdc-tab {
          padding: 0 5px;
          height: 40px;
          flex: 1;
          .mdc-tab-indicator__content--underline {
            border-top-width: $size-4;
            border-radius: $size-4 !important;
            border-color: $primary-darker;
          }
          &.mdc-tab--active {
            .mdc-tab__text-label {
              color: $primary-darker;
            }
          }
        }
      }
    }
  }
}

.templates-list-items {
  .list-item-content {
    .mat-mdc-checkbox {
      flex: 1;
      overflow: hidden;
    }
    .mdc-label {
      @include flex-combinator(row, nowrap);
      gap: $size-4;
    }
  }
}
