@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
/*font-family: 'Poppins', "Helvetica Neue", sans-serif;*/
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,500i,700,700i,900,900i&display=swap');
/*font-family: 'Roboto', sans-serif;*/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
/*font-family: 'Open Sans', sans-serif;*/
// @import "./icons.scss";
// @import 'bootstrap/dist/css/bootstrap.min.css';
@import "./theme.scss";
@import "./variables.scss";


.done:before {
  font-family: "Material Icons";
  content: "\e876";
}
/*.default-ng-select {
  @import "@ng-select/ng-select/themes/default.theme.css";
}*/
@import "@ng-select/ng-select/themes/material.theme.css";
// Mixins
@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

.entity-name, .year-end, .year-end2{
  //padding: 2px 7px;
  //background-color: #A7A7A7;
  border-radius: 5px;
}

.f-size-23 {
  font-size: 23px !important;
}

.t-capitalize {
  text-transform: capitalize !important;
}


.video-container {
  & .mat-dialog-container {
    padding: 0px;
    border-radius: 0px;
    border: 14px solid $gray-v11-color;
    overflow: visible;
  }
}
//Variables
/*$white-color: #ffffff;
$primary-color: #fccf5d;
*/
//
%full-width {
  display: inline-block;
  width: 100%;
}

%common-ico-setting {
  top: 12px;
  display: inline-block;
  margin-right: 8px;
}

%common-ico {
  width: 24px;
  height: 24px;
  position: relative;
}

%common-ico-before {
  display: inline-block;
  content: '';
  margin-right: 8px;
  width: 24px;
  height: 24px;
  background-size: 100% 100%;
}
//
.fade-in {
  -webkit-animation: fade-in 1s ease;
  -moz-animation: fade-in ease-in-out 1s both;
  -ms-animation: fade-in ease-in-out 1s both;
  -o-animation: fade-in ease-in-out 1s both;
  animation: fade-in 1s ease;
  visibility: visible;
  -webkit-backface-visibility: hidden;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fade-in {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@-o-keyframes fade-in {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes fade-in {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}
/*connected software icons*/
.qb-icon, .xero-icon, .freshbook-icon {
  display: flex;
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 40px;
  height: 40px;
  background-size: 100% 100%;
}

.qb-icon {
  background-image: url(/assets/images/quickbook/logo-small.png);
}

.xero-icon {
  background-image: url(/assets/images/xero/xero-icon.svg);
}

.freshbook-icon {
  background-image: url(/assets/images/freshbook/freshbook-icon.svg);
}

.qb-logo {
  background: url(/assets/images/quickbook/logo.svg) no-repeat 0 0;
}

.quickBooks-logo {
  background: url(/assets/images/quickbook/logo.svg) no-repeat 0 0;
  width: 124px;
  height: 24px;
}

.xero-logo {
  background: url(/assets/images/xero/logo.svg) no-repeat 0 0;
}

.freshbook-logo {
  background: url(/assets/images/freshbook/logo.svg) no-repeat 0 0;
}

.qb-connect {
  background: url(/assets/images/quickbook/connect.svg) no-repeat 0 0;
  width: 247px;
  height: 43px;
}

.sage-connect {
  background: url(/assets/images/sage/logo.jpg) no-repeat 0 0;
  width: 247px;
  height: 43px;
}

.xero-button1 {
  background: url(/assets/images/xero/logo.svg) no-repeat 0 0;
  width: 190px;
  height: 43px;
}

.xero-button2 {
  background: url(/assets/images/xero/disconnect.svg) no-repeat 0 0;
  width: 222px;
  height: 43px;
  display: block;
  border: none;
}

.mat-flat-button .mat-primary .xero-button
{
background-color: white !important;
font-size:100%;
height:43px;
width: 222px;
text-align:center;
}

.mat-flat-button .xero-button img
{
height:60%;
vertical-align:middle;
}


.xero-connect {
  background: url(/assets/images/xero/connect.svg) no-repeat 0 0;
  width: 190px;
  height: 43px;
}

.xero-disconnect {
  background: url(/assets/images/xero/disconnect.svg) no-repeat 0 0;
  width: 222px;
  height: 43px;
  display: block;
  border: none;
}

.freshbook-connect {
  background: url(/assets/images/freshbook/connect.svg) no-repeat 0 0;
  width: 192px;
  height: 46px;
}

.no-account-software {
  color: $blue-v2-color;
  display: flex;
  align-items: center;

  & .material-icons {
    color: #F26666;
    margin-right: 4px;
  }
}
//
.access-check-box {
  & > input {
    position: relative;
    z-index: -1;
  }
}
//
// .mat-focused .mat-form-field-required-marker {
//   color: $primary-secondary-color;
// }

// .mat-radio-button.mat-accent .mat-radio-inner-circle,
// .mat-radio-button.mat-accent
// .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
// .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
// .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
//   background-color: $primary-secondary-color;
// }

// .mat-radio-disabled {
//   & .mat-radio-label {
//     & .mat-radio-container {
//       & .mat-radio-inner-circle {
//         background-color: $gray-v26-color;
//       }

//       & .mat-radio-outer-circle {
//         border-color: $gray-v26-color;
//       }
//     }
//   }
// }

// .mat-flat-button.mat-primary {
//   &:disabled {
//     opacity: 0.4;
//     background-color: $primary-secondary-color;
//     color: $secondary-color;
//   }
// }

// .mat-stroked-button {
//   &:disabled {
//     opacity: 0.4;
//   }
// }

// .mat-button {
//   &:disabled {
//     & .mat-button-wrapper {
//       & .material-icons {
//         opacity: 0.5;
//       }
//     }
//   }
// }

// .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
//   border-color: $primary-secondary-color;
// }

// .mat-checkbox {
//   &.mat-checkbox-checked {
//     & .mat-checkbox-ripple .mat-ripple-element {
//       background-color: $primary-secondary-color;
//     }

//     & {
//       & .mat-checkbox-background {
//         background-color: $primary-secondary-color;
//       }
//     }
//   }
// }

// label.mat-checkbox-layout {
//   margin-bottom: 0px;
// }

// .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
//   background-color: $primary-secondary-color;
// }

// .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
//   color: $white-color;
//   background-color: $first-secondary-color;
//   border-radius: 4px;
// }

// .mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
//   color: $secondary-color;
// }

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 33%, rgba(0, 0, 0, 0.20) 0%);
}
//
.overlay {
  pointer-events: none;
}

.ngx-spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999 !important;

  &:empty {
    display: none;
  }

  & .loading-text {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%,-50%) !important;
  }
  /* & .overlay {
    position: fixed !important;
    pointer-events: none;
    z-index: 999999999 !important;
    width: 100%;
    height: 100vh;
    margin: 0;
  } */
}

.modifiedby {
  // max-width: 74px !important;
  margin-left: 8px !important;
}

.stopclick {
  pointer-events: none;
}

.file-extention-lbl {
  max-width: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.field-mat-input {
  max-width: 100%;
  width: auto;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  line-height: 1.125;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.87);
  outline: none;

  &:focus {
    border-bottom: 1px solid $primary-color;
  }
}

.corporation-expand-panel .mat-accordion .mat-expansion-panel-body ul li.itemstopclick {
  background-color: #9a9a9a;

  &:hover {
    background-color: #9a9a9a;
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.full-row {
  @extend %full-width;
  clear: both;
}

.full-wrapper {
  width: 100%;
}

.center-align {
  align-self: center;
}

.flex-box-vc-hl {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-box-vc-hr {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 102px;
}

.flex-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-box-row {
  display: flex;
  flex-flow: column nowrap;
}

.flex-box-col {
  display: flex;
  flex-flow: row nowrap;
}

.flex-box-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-box-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-box-left-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vcenter {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 100%;
}

.flleft {
  justify-content: flex-start;
  display: flex;
  flex-flow: nowrap;
  align-items: flex-start;
}

.flright {
  justify-content: flex-end;
  display: flex;
  flex-flow: nowrap;
  align-items: flex-start;
}



%priview-right-col-content {
  width: auto;
  display: block;
  float: right;
  text-align: right;
}

%year-cols {
  display: block;
  float: left;
  width: 120px;
  text-align: right;
  padding-right: 16px
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: normal;
  height: 100%;
  width: 100%;
}

// body {
//   overflow: hidden;
// }

// h1, h2, h3, h4, h5, h6, p, button, a, input, select, textarea, label {
//   font-family: 'Roboto', sans-serif;
// }

a {
  color: $primary-color;

  &:hover {
    color: $primary-color;
  }
}

.no-pad {
  padding: 0px !important;

  &-left {
    padding-left: 0px !important;
  }

  &-right {
    padding-right: 0px !important;
  }
}

.primary-color {
  color: $primary-color;
}

.gray-color {
  color: $gray-v9-color;
}

.gray-color2 {
  color: $gray-color;
}

.transparent-btn {
  background-color: transparent;
  border: none;
}

.dark {
  color: $white-color;
}

.expand-search {
  position: absolute;
  right: 0;
  display: flex;
  width: auto;
  justify-content: flex-end;
  padding: 0 7px 0 0px;

  &.field-expanded {
    width: 100%;
    padding: 0 7px 0 10px;
  }

  & .search-box {
    width: 0;
    opacity: 0;

    & input {
      width: 100%;
      border: none;
      border-bottom: 1px solid $gray-color;
      outline: none;
      transition: border ease-out 0.5s;

      &:focus, &:active {
        border-bottom: 2px solid $primary-secondary-color;
      }
    }

    &.search-box-enabled {
      width: 100%;
      opacity: 1;
    }
  }
}


// .custom-toggle {

//   & .mat-slide-toggle {
//     margin: 0px;
//   }

//   & .mat-slide-toggle-bar {
//     background-color: rgba(0,0,0,.38);
//   }

//   & .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
//     position: relative;
//     width: 31px;
//     height: 10px;
//     flex-shrink: 0;
//     border-radius: 8px;
//   }

//   & .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
//     height: 15px;
//     width: 15px;
//     border-radius: 50%;
//   }

//   & .mat-slide-toggle .mat-slide-toggle-ripple {
//     position: absolute;
//     top: calc(50% - 15px);
//     left: calc(50% - 15px);
//     height: 26px;
//     width: 26px;
//     z-index: 1;
//     pointer-events: none;
//     border-radius: 50%;
//   }

//   & .mat-slide-toggle-thumb {
//     height: 15px;
//     width: 15px;
//     border-radius: 50%;
//   }

//   & .mat-slide-toggle-bar {
//     position: relative;
//     width: 31px;
//     height: 10px;
//     flex-shrink: 0;
//     border-radius: 8px;
//   }
// }


.btn-trans-bord-radius {
  background-color: transparent;
  border: solid $white-color 1px !important;
  min-width: 150px;
  padding: 6px 0;
  text-align: center;
  color: $primary-color;
  border-radius: 0px !important;
}
//center element
.elem-p {
  text-align: center !important;
  justify-content: center;
}

.elem-c {
  display: inline-block !important;
}

.mat-flat-button, .mat-stroked-button, .mat-raised-button, .mat-button {
  border-radius: 0px;

  &.flat-btn {
    border-radius: 0px;
  }
}

.mat-flat-button {

  &.flat-btn {
    box-shadow: none;
    font-size: 14px;
    line-height: 29px;
    height: 30px;
    color: $secondary-color;
    font-weight: 600;
    border-radius: 0px !important;
  }

  &.btn-h-36 {
    line-height: 36px !important;
    height: 36px;
  }
}

.mat-button {

  &.flat-btn {
    box-shadow: none;
    font-size: 14px;
    line-height: 29px;
    height: 30px;
    font-weight: 600;
    border-radius: 0px !important;
  }
}

.mat-stroked-button {
  //line-height: 29px;
  // border: 1px solid $primary-secondary-color;
  // border-radius: 4px;
  // &:disabled{
  //   border-color:rgba(27, 160, 242, 0.5) !important;
  // }

  &.flat-btn {
    box-shadow: none;
    font-size: 14px;
    line-height: 29px;
    height: 30px;
    color: $secondary-color;
    font-weight: 600;
    border-radius: 0px !important;
    border: 1px solid $primary-secondary-color !important;
  }

  &.btn-h-34 {
    line-height: 34px !important;
    height: 34px;
  }

  &.btn-h-32 {
    line-height: 30px !important;
    height: 30px;
  }


  &.full-width {
    width: 100% !important;
  }

  &.primary-border {
    border: 1px solid $primary-secondary-color !important;
  }
}

.mat-raised-button {

  &.secondary-btn, &.rounded-btn {
    border-radius: 0px !important;
    box-shadow: none;
    font-size: 14px;
    line-height: 29px;
    height: 30px;
    color: $secondary-color;
    font-weight: 600;

    &.rounded-6 {
      border-radius: 6px !important;
      padding: 3.5px 7px;
      min-width: 0px;
    }

    &.primary-border {
      border: 1px solid $primary-secondary-color !important;
    }
  }

  &.flat-btn {
    box-shadow: none;
    font-size: 14px;
    line-height: 29px;
    height: 30px;
    color: $secondary-color;
    font-weight: 600;
    border-radius: 0px !important;
  }

  &.full-width {
    width: 100% !important;
  }

  &.primary-border {
    border: 1px solid $primary-secondary-color !important;
  }

  &.mat-secondary {
    border: 1px solid $primary-secondary-color;

    &.flat-btn {
      border-radius: 0px !important;
    }

    &.primary-border {
      border: 1px solid $primary-secondary-color !important;
    }
  }
}

.svg-icon {
  display: inline-block;
  /*width: 70px;
  height: 70px;*/
  width: 40px;
  height: 40px;
  background-size: cover;
}

.drawer-full {

  nav {
    ul {
      li {
        a {
          .lable {
            display: block;
            width: 195px;
            transition: width 0.5s ease-in-out, display 0.5s ease-in-out;
          }
        }
      }
    }
  }
}

.drawer-small {

  nav {
    ul {
      li {
        a {
          .lable {
            display: none;
            width: 0px;
            transition: width 0.5s ease-in-out;
          }
        }
      }
    }
  }
}

.tb-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.ico-bg-circle-48 {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background-color: $primary-color;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  > span.icon-tick {
    font-size: 22px;
  }

  > span.icon-forgot-password {
    font-size: 28px;
  }

  > span.icon-new-business {
    font-size: 24px;
  }
}

.strong {
  font-weight: 500;
}

.dialogBoxActions {
  min-width: 360px;

  &.mat-dialog-actions {
    padding: 30px 0 20px 0;
    @extend %full-width;

    & .secondary-btn {
      box-shadow: none;
      color: $secondary-color;

      &.l30 {
        line-height: 30px;
      }

      &.mat-secondary {
        border: 1px solid $primary-secondary-color;
        line-height: 28px;
        min-width: 90px;
        background-color: $white-v1-color;

        &:hover {
          background-color: $gray-v6-color;
        }
      }

      &.mat-primary {
        min-width: 90px;
      }
    }
  }
}

.mar-b-8 {
  margin-bottom: 8px !important;
}
.backg-white{
  background: #fff;
}
.mar-b-15{
  margin-bottom: 15px
}
.bottom-br {
  border-bottom: 1px solid #BAB9BD;
}

.mar-r-8 {
  margin-right: 8px !important;
}

.mar-r-12 {
  margin-right: 12px !important;
}
.mar-r-20 {
  margin-right: 20px !important;
}
.dark-gray-color{
color: $black-color;//#6D6D6D;
}
.dark-gray2{
  color: #424242;
  margin-bottom: 11px;
}
.light-green{
  background: #DDF0DF;
}
.white-color{
  color: #fff !important;
}
.top-left-cross-btn{
  position: relative;
    bottom: 20px;
    left: 25px;
    color: #9A9A9A;
}
.blue{
  color: #005EAC;
}
.black-color{
  color: #0F0F0F !important;
    font-weight: 500;
}
.position{
  position: relative;
    bottom: 46px;
}
.position-top{
  position: relative;
  top: 10px;
  right: 7px;
  text-decoration: underline;
}
.position-l{
  position: relative;
    left: 16px;
}
.f-roboto{
  font-family: Roboto;
  font-size: 12px;
  line-height: 16px;
  margin-left: 17px;
}
.f-s-12{
  font-size: 12px;
  font-weight: 500;
}
.f-w-400{
  font-weight: 400;
}
.green-color{
  color: #fff;
  background-color:#339C7D ;
}
.width-120{
  width: 120px;
}
.w-132{
  width: 132px;
}
.f-15{
  font-size: 15px;
    margin-right: 4px;
}
.f-16{
  font-size: 16px;
    margin-right: 5px;
    margin-top: -1px;
}
.font-w-6{
  font-weight: 600;
}
.Burgundy-color{
  color: #990000;
}
.blue-color{
  color:#337EBD !important;
}
.mar-b-5 {
  margin-bottom: 5px !important;
}
.mar-b-10{
  margin-bottom: 10px;
}
.mar-b-14 {
  margin-bottom: 14px !important;
}
.mar-t-22{
  margin-top: 22px;
}
.mar-t-6{
  margin-top: 6px !important;
}
.pad-t-22{
  padding-top: 22px;
}
.mar-t-10{
  margin-top: 10px !important;
}
.mar-tb-22{
  margin-top: 24px;
    margin-bottom: 22px;
}
.mar-tb-33{
  margin-top: 24px;
  padding-bottom: 33px;
}
.mar-t-50{
  margin-top: 50px;
}
.mar-t-20 {
  margin-top: 20px !important;
}
.mar-t-16{
  margin-top: 16px;
}
.padding-15{
  padding: 15px
}
.mar-tb-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.mar-r-16 {
  margin-right: 16px !important;
}
.mar-r-22{
  margin-right: 22px !important;
}
.mar-l-16 {
  margin-left: 16px !important;
}
.border-r-4{
  border-radius: 4px !important;
}
.mar-r-24 {
  margin-right: 24px !important;
}

.mar-r-32 {
  margin-right: 32px !important;
}
.mar-b-3{
  margin-bottom: 3px;
}
.mar-r-5{
  margin-right: 5px !important;
}
.mar-top-24 {
  margin-top: 24px !important;
}

.mar-tb-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mar-t-35p {
  margin-top: 35% !important;
}
.gray-color{
  color: #9A9A9A;
}
.mar-l-15{
  margin-left: 15px
}
.mar-l-5{
  margin-left: 5px;
}
.mar-b-20 {
  margin-bottom: 20px;
}
.top{
  position: relative;
    top: 9px;
}
.buttom-position{
  position: relative;
    bottom: 6px;
}
.buttom-p-4{
  position: relative;
    bottom: 4px;
}
.top-6{
  position: relative;
    top: 6px;
}
.mar-b-30{
  margin-bottom: 30px;
}
.ico-header {
  margin-top: 25px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.28em !important;
}

.full-width-100 {
  width: 100%;
}

.mat-placeholder-required {
  color: $red-v1-color;
}

.mat-focused .mat-form-field-required-marker {
  color: $red-v1-color;
}

.clear-bodySpace-top {
  margin-top: -24px;
}

.common-font {
    font-size: 14px;
}

.clr-button {
  color: $primary-color;
  margin-bottom: 4px;
}

.ntr-title {
  font-size: 19px;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 16px;
}

.mat-tab-label-active {
  opacity: 1 !important;
}

.pointer {
  cursor: pointer !important;
}

.flyOut {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 115px;
  z-index: 9999;
  width: 825px;
  background-color: $white-color;
  -webkit-box-shadow: -5px 0px 3px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: -5px 0px 3px 0px rgba(0,0,0,0.1);
  box-shadow: -5px 0px 3px 0px rgba(0,0,0,0.1);

  &-header {
    width: 100%;
  }
}

.previewSheet {
  position: fixed;
  z-index: 9999;
  background-color: $white-v2-color;
  padding: 32px;
  top: 0;
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
}

.report {
  &-sheet {
    max-width:720px;
    min-height: 1032px;
    @extend %full-width;
    border-radius: 5px;
    border: 1px solid $gray-v2-color;
    margin: 24px auto;
    background: $white-color;
    padding: 40px;
    position: relative;

    & > .left-border {
      position: absolute;
      width: 1px;
      top: 217px;
      bottom: 0px;
      left: 100px;
      border-left: 1px dotted $gray-v4-color;
    }

    & > .right-border {
      position: absolute;
      width: 1px;
      top: 217px;
      bottom: 0px;
      right: 190px;
      border-left: 1px dotted $gray-v1-color;
    }

    &-actions {
      @extend %full-width;
      max-width: 1024px;
      margin: 24px auto 0px auto;

      & .action-items {
        display: block;
        float: left;
        margin-right: 16px;

        &:last-child {
          margin-right: 0px;
        }

        & .actions-dropdown {
          position: absolute;
          background-color: $white-color;
          top: 100%;
          left: 0;
          display: inline-block;
          padding: 8px 0;
          margin: 0;
          list-style: none;
          z-index: 2;
          width: 100%;
          min-width: 112px;
          max-width: 280px;
          overflow: auto;
          -webkit-overflow-scrolling: touch;
          max-height: calc(100vh - 48px);
          border-radius: 4px;
          outline: 0;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

          & a {
            background: transparent;
            color: rgba(0, 0, 0, 0.87);
            font-size: 14px;
            font-weight: 400;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
            outline: 0;
            border: none;
            -webkit-tap-highlight-color: transparent;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            line-height: 48px;
            height: 48px;
            padding: 0 16px;
            text-align: left;
            text-decoration: none;
            max-width: 100%;
            position: relative;

            &:hover, &:focus {
              background: rgba(0, 0, 0, 0.04);
            }
          }
        }
      }
    }

    &-wrapper {
      @extend %full-width;

      &-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        width: 100%;
      }
    }

    &-header {
      @extend %full-width;

      &-title {
        margin-top: 0px;
        height: auto;
        overflow: hidden;
        display: block;
        clear: both;
        width: 100%;

        h4, h3 {
          margin: 0px 0px 8px 0px;
          text-align: center;
        }
      }

      &-subtitle {
        height: auto;
        overflow: hidden;
        margin-top: 0;

        h6 {
          font-size: 16px;
          margin: 0px;
          /*text-align: center;*/
        }

        & .statement-year {
          margin: 5px 0 0 0;
          color: $secondary-v2-color;
          font-size: 14px;
          /*text-align: center;*/
        }
      }
    }

    &-body {
      @extend %full-width;
      /*old preview statement css not in use*/
      & .sheet-content {
        @extend %full-width;

        & .heading {
          margin: 8px 0 0px 0;
          font-size: 16px;
          font-weight: 700;
          color: $black-color;
        }

        & .subheading {
          margin: 10px 0 8px 0;
          font-size: 14px;
          font-weight: 700;
          color: $black-color;
        }

        & .child-label {
          margin: 0px;
          font-size: 14px;
          font-weight: normal;
          color: $black-color;
        }

        & .mat-nested-tree-node ul {
          padding-left: 0;

          & .heading {
            margin: 0;
            font-size: 14px;
            font-weight: 700;
            color: $black-color;
          }

          ul {
            padding-left: 16px;
          }
        }

        & .sheet-right {
          @extend %priview-right-col-content;

          h6 {
            margin: 0px;
            font-weight: bold;
            font-size: 14px;
          }

          & .year-cols {
            @extend %year-cols;

            &.corrent-year {
              width: 126px;
              padding-right: 16px !important;

              & h6 {
                color: $secondary-v2-color;
              }
            }

            &.prev-year {
              padding-right: 0px;

              & h6 {
                color: $secondary-v2-color;
              }
            }
          }
        }

        &-header {
          @extend %full-width;
          margin-top: 16px;
          /*height: 50px;*/
          border-top: 1px solid $secondary-color;
          border-bottom: 1px solid $secondary-color;
          padding: 12px 0;

          h6 {
            margin: 0px;
            font-size: 12px;
            color: $secondary-color;
            /*line-height: 50px;*/
          }

          & .sheet-left {
            width: 60px;
            display: inline-block;
            padding-left: 10px;
          }

          & .sheet-middle {
            width: calc(100% - 150px);
            display: inline-block;
          }
        }

        &-body {
          @extend %full-width;

          & .sheet-right {
            & h6 {
              font-size: 14px;
            }
          }

          & > .sheet-tree {
            & > .mat-nested-tree-node {
              & > li.loop-elements {
                & > ul {
                  position: relative;
                }
              }
            }
          }

          & .loop-elements {
            border-bottom: 2px solid $secondary-color;
            padding-bottom: 0px;

            & .sheet-right {
              & h6 {
                font-size: 14px;
              }
            }

            & .loop-elements {
              border-bottom: none !important;

              & .sheet-assets {
                &.rtl-flow {
                  width: 100%;
                  margin-left: 0px;
                }
              }

              & .sheet-right {
                padding-right: 0px;
              }
            }
          }

          & .mat-icon-button[disabled] {
            min-height: 0px;
            height: 0px;
            width: 0px;
          }

          & li ul .sheet-assets {
            width: 100%;

            & .sheet-middle {
              padding-left: 0px;
            }
          }

          & .mat-tree-node {
            min-height: 32px;
            justify-content: space-between;
          }

          h6, p {
            margin: 0px;
            font-size: 14px;
            color: $secondary-color;
          }

          & .sheet-left {
            width: 60px;
            display: inline-block;
            padding-left: 0px;

            &.no-left-content {
              width: 0px;
            }
          }

          & .sheet-assets {
            /*width: calc(100% - 60px);*/
            display: flex;
            height: auto;
            overflow: hidden;

            &.rtl-flow {
              display: flex;
              flex-flow: row nowrap;
              justify-content: flex-end;
              margin-left: 0px;

              &:after {
                content: '';
                width: 100%;
                height: 34px;
                /*border-bottom: 1px dotted $gray-v2-color;*/
                position: absolute;
                left: 0px;
                right: 0px;
                margin-top: 0px;
                border-top: 1px solid $secondary-color;
              }

              > div {
                float: none;
              }

              & .sheet-left, & .sheet-right {
                padding: 8px 0;
              }

              & .sheet-middle {
                width: calc(100% - 150px);
                /*padding: 8px 0;*/
              }
            }

            & > div {
              float: left;
            }

            & .mat-icon-button {
              width: 24px;
              height: 24px;
              line-height: 24px;

              & .mat-icon {
                font-size: 18px;
                color: $gray-color;
              }
            }

            & .mat-tree-node {
              min-height: 24px;
            }

            & .sheet-middle {
              width: calc(100% - 150px);
              display: inline-block;
              /*padding-left: 10px;*/
            }
            /*& .sheet-right {
              width: 150px;
              display: inline-block;
              padding-right: 10px;
              vertical-align: top;
              text-align: right;

              & h6, & p {
                text-align: right;
              }
            }*/
          }
        }

        &-footer {
          @extend %full-width;
        }
      }
      /*preview statement*/
      & .statement {
        &-content {
          @extend %full-width;

          & .year-cols {
            @extend %year-cols;

            h6 {
              margin: 0px;
              font-size: 14px;
              line-height: 24px;
              padding-top: 0px;
              padding-bottom: 0px;
              line-height: 20px;
            }

            &.corrent-year {
              & > h6 {
                font-weight: 600;
              }
            }

            &.prev-year {
              & > h6 {
                font-weight: normal;
              }
            }
          }

          &-header {
            @extend %full-width;
            padding: 12px 0;
            background-color: $white-color;
            border-top: 1px solid $secondary-color;
            border-bottom: 4px solid $secondary-color;

            & .right-section {
              @extend %priview-right-col-content;

              h6 {
                margin: 0px;
                font-weight: bold;
                font-size: 14px;
              }

              & .year-cols {
                @extend %year-cols;

                &.corrent-year {
                  width: 126px;
                  padding-right: 16px;

                  & h6 {
                    color: $secondary-v2-color;
                  }
                }

                &.prev-year {
                  padding-right: 0px;

                  & h6 {
                    color: $secondary-v2-color;
                  }
                }
              }
            }
          }

          &-body {
            display: inline-block;
            width: 100%;

            & .heading {
              margin: 22px 0 5px 0;
              font-size: 14px;
              font-weight: 700;
              color: $black-color;
              padding-left: 12px;
            }

            & .subheading {
              margin: 0 0 6px 0;
              font-size: 14px;
              font-weight: 700;
              color: $black-color;
              padding-left: 12px;
            }

            & .child-label {
              margin: 0px;
              font-size: 14px;
              line-height: 24px;
              font-weight: normal;
              color: $black-color;
              padding-left: 6px;
            }

            & ul {
              margin: 0;
              padding: 0;
              display: inline-block;
              width: 100%;
              list-style: none;

              &.equity-ul {
                & h5.heading {
                  margin-bottom: 5px;
                }
              }

              &.null-ul {
                padding: 8px 0;

                & li.null-child {
                  height: 16px;
                  padding-top: 0;

                  & h6.subheading {
                    margin: 0;
                  }

                  & .full-section {
                    padding: 0px;
                  }
                }
              }

              &.main-ul > li > ul {
                padding-left: 0px;
              }

              & li {
                display: inline-block;
                width: 100%;
                margin: 0;
                padding: 0px 0;
                line-height: 1;

                &.no-child {
                  & .subheading {
                    margin-bottom: 0px;
                    display: inline-block;
                  }
                }

                &.has-child {
                  padding-bottom: 0px;
                }

                &.null-child {
                  /*border-top: 1px dotted $secondary-color;
                  border-bottom: 1px dotted $secondary-color;*/
                  & ul {
                    padding: 0px;
                    margin: 0px;
                    width: 0px;
                    height: 0px;
                    display: none;
                    float: left;
                  }

                  & h6.subheading {
                    display: block;
                    float: left;
                  }

                  & .full-section {
                    width: 272px;
                    float: right;
                    clear: none;
                    border: none;

                    & .right-section {
                      display: flex;
                      flex-flow: row nowrap;
                    }
                  }
                }

                & ul {
                  padding-left: 10px;
                }
              }

              &.main-ul {
                & > li {
                  padding: 0px;
                }
              }
            }

            & .section-total {
              & .year-cols.corrent-year {
                & > h6 {
                  font-weight: 600;
                }
              }

              & .year-cols.prev-year {
                & > h6 {
                  font-weight: normal;
                }
              }
            }

            & .full-section {
              @extend %full-width;
              padding: 9px 0 8px 0;
              border-top: 1px dotted $secondary-color;
              /*border-bottom: 1px dotted $secondary-color;*/
              float: left;
              clear: both;
              /*margin-bottom: 16px;*/
              &.section-total {
                border-top: 1px solid $secondary-color !important;
                border-bottom: 2px solid $secondary-color;
                margin-bottom: 0px;

                &.liability-equity-wrapper {
                  position: relative;
                  top: -6px;
                  z-index: 1;

                  & h6.subheading {
                    margin: 0px;
                  }
                }

                &.liability-wrapper {
                  border-top: 1px solid $secondary-color;
                  border-bottom: 2px solid $secondary-color;
                }

                &.equity-wrapper {
                  border-top: 1px solid $secondary-color !important;
                  border-bottom: 2px solid $secondary-color !important;
                }

                & .year-cols.corrent-year {
                  & > h6 {
                    font-weight: 600;
                  }
                }

                & .year-cols.prev-year {
                  & > h6 {
                    font-weight: normal;
                  }
                }
              }
            }

            & .left-section {
              display: block;
              float: left;
              width: calc(100% - 272px);
              padding-left: 12px;
            }

            & .right-section {
              @extend %priview-right-col-content;
              padding-right: 12px;

              & .year-cols {
                @extend %year-cols;

                h6 {
                  font-size: 14px;
                }

                &.corrent-year {
                  width: 126px;
                  padding-right: 16px !important;

                  & h6 {
                    color: $secondary-v2-color;
                  }
                }

                &.prev-year {
                  padding: 0px;

                  & h6 {
                    color: $secondary-v2-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.report-sheet-wrapper.balance-sheet-preview-wrapper {
  & .report-sheet-body .statement-content-header .right-section .year-cols.prev-year {
    padding-right: 12px;
  }
  /*  & .report-sheet-body .statement-content-header .right-section .year-cols.corrent-year{
    margin-right: 12px;
  } */
}

.no-child {
  & .subheading {
    margin-bottom: 0px !important;
    display: inline-block !important;
  }
}

.has-child {
  padding-bottom: 0px !important;
}

.mat-nested-tree-node {
  ul, li {
    list-style: none;
  }

  ul {
    margin: 0px;
  }
}

.mat-tree-node {
  & .sheet-assets {
    width: 100%;
  }
}

.field-with-noborder {
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 0px !important;
  }

  .field-with-textwidth {
    width: 100px;
  }
}

button {
  outline: none !important;
}

// .mat-option {
//   &.mat-active {
//     color: $secondary-v2-color !important;
//   }
// }

.cvr-footer {
  margin-bottom: 24px;
  margin-top: 14px;
}

.comp-footer {
  margin-bottom: 24px;
  margin-top: 24px;
}

.tbl-gridv2 {
  & .mat-table {

    & tbody tr td {
      border-right: 1px dotted $gray-v2-color;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

th.col-actions.mat-header-cell.mat-column-actions {
  max-width: 140px;
  width: 140px;
}

// .mat-table thead tr th, .mat-sort-header-button {
//   text-transform: uppercase;
// }

.mat-table thead tr th {
  & .actions-items {
    justify-content: center;
    // text-transform: uppercase;
  }
}

.bold-label {
  font-weight: bold;
}

.group-total {
  color: green !important;
}
//
.dailog-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.mat-button-focus-overlay {
  opacity: 0 !important;
}

.firm-form-field, .firm-select-field, .firm-input-field, .firm-date-field, .custom-input, .formfield, .search-field, .mat-form-field {
  //margin-top: 8px;
  &.mat-form-field-appearance-legacy {
    &.mat-focused {
      & label.mat-form-field-label {
        color: #A19E9E !important;
        font-weight: normal !important;
        font-size: 14px !important;
        transform: translateY(-18px) scale(1) perspective(100px) translateZ(0.00106px) !important;

        & .mat-placeholder-required {
          color: #f44336;
        }
      }
    }

    & .mat-form-field-infix {
      padding-top: 8px;
    }
  }

  &.mat-form-field-can-float.mat-form-field-should-float {
    & .mat-form-field-label {
      color: #A19E9E !important;
      font-weight: normal !important;
      font-size: 14px !important;
      transform: translateY(-18px) scale(1) perspective(100px) translateZ(0.00106px) !important;
    }
  }

  &.custom-hide-label {
    &.mat-form-field-can-float.mat-form-field-should-float {
      & .mat-form-field-label {
        visibility: hidden;
      }
    }
  }
}

.custom-checkbox {
  &.mat-checkbox {
    &.mat-checkbox-checked {
      & .mat-checkbox-ripple .mat-ripple-element {
        background-color: $primary-secondary-color;
      }

      & {
        & .mat-checkbox-background {
          background-color: $primary-secondary-color;
        }
      }
    }
  }

  & label.mat-checkbox-layout {
    margin-bottom: 0px;
  }

  &.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: $primary-secondary-color;
  }
}

.custom-radio-button {
  &.mat-radio-checked {
    & .mat-radio-label {
      & .mat-radio-container {

        & .mat-radio-ripple.mat-ripple {
          display: none !important;
          width: 0px !important;
          height: 0px !important;
        }

        & .mat-radio-outer-circle {
          border-color: $primary-secondary-color;
        }

        & .mat-radio-inner-circle {
          background-color: $primary-secondary-color;
        }

        & .mat-radio-ripple mat-ripple {
          position: static;

          & .mat-ripple-element {
            &.mat-radio-persistent-ripple {
              background-color: $primary-secondary-color;
            }
          }
        }

        & .mat-checkbox-ripple .mat-ripple-element {
          background-color: $primary-secondary-color;
        }
      }
      /*

      &.mat-accent {
        & .mat-checkbox-background {
          background-color: #008265;
        }
      }*/
    }
  }
}

.status-block {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 3px;

  &.active {
    background-color: $teal-v1-color;
  }

  &.invited {
    background-color: $beige-color;
  }

  &.new {
    background-color: $sky-blue-v1-color;
  }

  &.rejected {
    background-color: $light-pink-v2-color;
  }

  &.yettoinvite {
    background-color: $sky-blue-v3-color;
  }

  &.archive {
    background-color: $archived-color;
  }
}

.filterContent {
  height: auto;
  overflow: hidden;
  padding-top: 10px;

  .mat-menu-content {
    padding: 0 16px;

    .mat-checkbox {
      display: inline-block;
      width: 100%;
    }
  }

  &.action-content {
    .mat-menu-content {
      padding: 4px;
    }

    // button.mat-menu-item {
    //   font-size: 12px;
    //   line-height: normal;
    //   padding: 8px 12px;
    //   height: auto;
    //   color: $secondary-color;
    // }
  }

  &.status-lbl {
    width: auto;
    max-width: 140px;
  }

  &.entity-lbl {
    width: auto;
    max-width: 170px;
  }
}

.client-list {
  width: 100%;
  display: inline-block;
  padding: 0 0px 24px 0px;

  &-header {
    width: 100%;
    display: flex;
    padding: 0 0 16px 0;
    /*margin-bottom: 24px;*/
    margin-bottom: 10px;
    border-bottom: 1px solid $gray-v2-color;

    & .items {
      margin-right: 62px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      &:last-child {
        margin-right: 0px;
      }

      p {
        font-size: 16px;
        margin: 0 12px 0 0;
        color: $gray-v4-color;
      }

      h1 {
        font-size: 25px;
        color: $secondary-color;
        margin: 0px;
        font-weight: 500;
        text-align: right;
      }
    }
  }
}

.no-border-bottom {
  border-bottom: none !important;
  margin-bottom: 0px !important;
}

.file-wrapper {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;

  & input {
    cursor: pointer;
    font-size: 100px;
    filter: alpha(opacity=1);
    -moz-opacity: 0.01;
    opacity: 0.01;
    position: absolute;
    right: 0;
    top: 0;
  }

  & .button {
    cursor: pointer;
    display: inline-block;
    padding: 0 0 0 6px;
    text-decoration: underline;
    color: $hyper-link-color;
  }
}

.cdk-global-scrollblock {
  overflow-y: auto;
}
/*Progress bar*/
.firm-section-wrapper {
  & .mat-expansion-panel-header-description {
    width: 200px;
    flex-flow: row wrap;
    justify-content: flex-end;
    display: flex;
    max-width: 200px;
    align-items: center;
    height: 48px;
    margin-right: 0;
  }

  & .mat-accordion .mat-expansion-panel {
    /*height: 70px;*/
    margin-bottom: 44px;
    width: 1024px;

    &.mat-expanded {
      border: 1px solid $primary-color;

      & .mat-expansion-panel-header-title {
        h3 {
          line-height: 64px;
          margin: 0px;

          &:hover {
            color: $gray-v2-color;
          }
        }
      }
    }

    & .mat-expansion-panel-header-title {
      h5 {
        line-height: 48px;
        margin: 0px;
      }
    }

    &:last-of-type {
      margin-bottom: 0px;
    }
    /*&.mat-expanded {
      height: auto;
    }*/
    &-header {
      /*height: 70px !important;*/
      & h3 {
        margin: 0 0 0 0;
        align-self: center;
        font-size: 24px;
      }
    }

    &-content {
      & .sub-title {
        font-size: 19px;
        font-weight: normal;
        margin-bottom: 24px;
      }
    }

    &:first-of-type, &:last-of-type {
      border-radius: 0px !important;
    }
  }
}

.custom-progress-bar {
  width: 100%;
  align-self: unset;
  display: flex;
  flex-flow: column;


  & .progress-status-value {
    display: block;
    text-align: right;
  }

  & .mat-progress-bar {
    width: 150px;
    display: block;
    height: 6px;
  }

  & .mat-progress-bar-buffer, & .mat-progress-bar-background {
    background-color: #bdbdbd;
    border-radius: 3px;

    &.mat-progress-bar-element {
      height: 6px;
      margin-top: 0px;
    }
  }

  & .mat-progress-bar-fill {
    border-radius: 30px;

    &:after {
      border-radius: 30px;
      background-color: #2aa231 !important;
    }
  }
}
/**/
.eng-progress-bar {
  width: 100%;
  align-self: unset;
  display: flex;
  flex-flow: column;

  & .progress-status-value {
    display: block;
  }

  & .mat-progress-bar {
    width: 110px;
    display: block;
    height: 6px;
  }

  & .mat-progress-bar-buffer, & .mat-progress-bar-background {
    background-color: #f2f2f2;
    border-radius: 3px;

    &.mat-progress-bar-element {
      height: 6px;
      margin-top: 0px !important;
    }
  }

  & .mat-progress-bar-fill {
    border-radius: 3px !important;
    height: 6px !important;

    &:after {
      height: 6px;
      border-radius: 3px !important;
    }
  }
}

.addnew-section {
  position: relative;

  & tbody:before {
    margin-top: 0;
    content: "_";
    color: white;
    display: block;
    margin-bottom: 32px;
  }

  & .new-partnersection {
    height: auto;
    overflow: hidden;
    // position: absolute;
    width: 100%;
    background-color: white;
    z-index: 1;
    padding-top: 10px !important;

    & .form-fields {
      display: inline-block;
      width: 100%;
    }

    .new-partnerborder {
      //border: 1px solid $primary-color;
      //padding: 20px;
      height: auto;
      position: relative;
      //margin-right: 20px;
      & .close-btn {
        position: absolute;
        top: -15px;
        left: 100px;
        background: #fff;
        color: $primary-color;
        border: 1px solid $dark-primary-text;
        box-sizing: border-box;
        border-radius: 100%;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        padding: 4px 0 0 0;

        & .material-icons {
          font-size: 18px;
        }
      }
    }

    & .partner-header {
      font-size: 20px;
      font-weight: 600;
    }

    & .partner-inputfields {
      margin-top: 20px;
    }
  }
}

.flx-row {
  display: flex;
  flex-flow: row nowrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;

  & .flx-col {
    flex: 1 0 auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  &.col-right {
    justify-content: flex-end;
    width: 100%;
    padding-right: 30px;
  }
}

.columns {
  &-4 {
    & > .flx-col {
      width: calc(100% / 4);
      min-width: calc(100% / 4);
      max-width: calc(100% / 4);

      &.flx-col-row {
        max-width: 100%;
      }
    }
  }

  &-5 {
    & > .flx-col {
      width: calc(100% / 5);
      min-width: calc(100% / 5);
      max-width: calc(100% / 5);

      &.flx-col-row {
        max-width: 100%;
      }
    }
  }

  &-6 {
    & > .flx-col {
      width: calc(100% / 6);
      min-width: calc(100% / 6);
      max-width: calc(100% / 6);

      &.flx-col-row {
        max-width: 100%;
      }
    }
  }
}

.reupload-btn {
  background-color: $white-color !important;
  border: 1px solid $primary-secondary-color !important;
}

.search-field {
  width: 260px;
  margin: 0px 24px 0 0;
  position: relative;

  input {
    width: 100%;
    height: 32px;
    padding-right: 0px !important;
    padding-left: 8px;
    border: none;
    border: 1px solid #BAB9BD;
    background-color: transparent;
  }

  & .search-btn {
    // position: absolute;
    // right: 0;
    // margin: 0px !important;
    // top:5px;

    & .material-icons {
      color: $primary-icon-color !important;
    }

    &:disabled {
      & .material-icons {
        color: rgba(0,0,0,.26) !important;
      }
    }
  }
}

.mat-form-field.mat-focused {
  & .mat-form-field-label {
    color: rgba(0, 0, 0, 0.54);
  }
}

.mat-radio-group.filteredItems {
  width: 100%;
  display: inline-block;
  max-height: 150px;
  overflow-y: auto;
  padding: 15px 0 7.5px 0;
  margin-bottom: 7.5px;
  min-height: 40px;

  & .row-item {
    height: 30px;
    overflow: hidden;
  }

  & .mat-radio-button {
    display: flex;
    flex-flow: row nowrap;

    & .mat-radio-label {
      display: flex;
      flex-flow: row nowrap;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      margin: 0px;
    }
  }
}

.disabled-item {
  a {
    cursor: default;

    & i {
      color: #7b7777;
    }
  }
}

.full-width {
  display: inline-block;
  width: 100%;
}

.btn-center-ico {
  display: flex;
  align-items: center;

  & .ico {
    margin-right: 6px;
  }
}

.btn-default {
  padding: 4px 8px !important;
}

.signoff-btn {
  & button.mat-menu-item {
    line-height: 32px !important;
    height: 32px !important;
  }
}

.primary-color {
  color: $primary-color !important;
}

.content-overlay {
  & .mat-menu-content {
    padding: 0 !important;
  }
}

.uppercase {
  text-transform: uppercase !important;
}

.no-marg {
  margin: 0px !important;
}

.ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
}

.ellipse-namewidth {
  width: 200px;
  max-width: 200px;
}

.ellipse-emailwidth {
  width: 250px;
  max-width: 250px;
}

.ellipse-150width {
  width: 150px;
  max-width: 150px;
}

.ellipse-100width {
  width: 100px;
  max-width: 100px;
}

.disabledtextfield-border.mat-form-field-disabled .mat-form-field-wrapper .mat-form-field-underline {
  background-image: none !important;
  background-color: #949494;
}

.tbl-menulist {
  &.filter-sort.clients-assign-sort {
    max-width: 135px;
    min-width: 135px;

    & .mat-menu-content {
      width: 100%;
    }
  }

  & .mat-menu-item {
    height: 40px;
  }

  & .mat-menu-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  & .tbl-filtersavebtn {
    padding: 1px 30px;
    font-size: 14px;
    margin: 10px 0 20px 15px;
    color: $black-v3-color;
    border: 1px solid $gray-v13-color;
  }

  & .tbl-scroll {
    max-height: 250px;
    min-height: 32px;
    overflow-y: auto;
  }
}

.btn-ico-color {
  color: $action-icons-color;
}

.actions-items {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  // &::after {
  //   content: "";
  //   position: absolute;
  //   width: 1px;
  //   background-color: #e0e0e0;
  //   left: -16px;
  //   z-index: 111;
  //   height: 52px;
  // }

  & button {
    padding: 3px;
    margin-left: 8px;
    color: $action-icons-color;
    width: 24px;
    max-width: 24px;
    height: 24px;

    &:hover:not(:disabled) {
      color: $action-icons-color;
    }

    &:disabled {
      opacity: 0.4;
    }

    &:first-child {
      margin-left: 0px;
    }

    & .material-icons {
      font-size: 21px;
      line-height: 21px;
    }
  }
}
/*p.status-block {
  margin-right: 15px;
}*/
@media (min-width: 1800px) and (max-width:1920px) {

  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-desabled.signOff-enabled,
  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-disabled,
  .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-enabled,
  .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-enabled {
    & .tBalanceitems button.secondary-btn.mat-secondary .mat-button-wrapper .tbh-act-lbl {
      display: none;
    }

    & .tBalanceitems {
      & .tb-srch {
        &.search-field-expanded {
          //width: calc(100% - 660px);
          border: none;
          width: 300px;
          padding-top: 10px;
        }
      }
    }
  }

  .ntr-panel.ntr-Left-side-menu-desabled.ntr-right-panel-desabled.signOff-disabled,
  .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-disabled, .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-desabled.signOff-enabled,
  .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-enabled,
  .ntr-panel.ntr-Left-side-menu-desabled.ntr-right-panel-enabled.signOff-disabled, .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-desabled.signOff-disabled {
    & .small-screen {
      display: none;
    }
  }

  .ntr-panel.ntr-Left-side-menu-desabled.ntr-right-panel-desabled.signOff-disabled,
  .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-disabled,
  .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-desabled.signOff-enabled,
  .ntr-panel.ntr-Left-side-menu-desabled.ntr-right-panel-enabled.signOff-disabled,
  .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-desabled.signOff-disabled,
  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-desabled.ntr-right-panel-desabled.signOff-disabled,
  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-desabled.signOff-disabled,
  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-desabled.ntr-right-panel-enabled.signOff-disabled {
    & .tBalanceitems {
      & .tb-srch {
        &.search-field-expanded {
          //width: calc(100% - 610px);
          border: none;
          width: 620px;
          padding-top: 10px;
        }
      }
    }
  }

  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-enabled {
    & .small-screen {
      display: flex;
    }

    & .large-screen {
      display: none;
    }

    & .tBalance-right {
      & .tBalanceitems {

        & .action-btn {
          top: 3px;
        }
      }
    }
  }

  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-desabled.signOff-disabled {
    & .tBalanceitems button.secondary-btn.mat-secondary .mat-button-wrapper .tbh-act-lbl {
      display: flex;
    }
  }
}

@media (min-width: 2201px) {
  .tBalanceitems {
    & .tb-srch {
      &.search-field-expanded {
        //width: calc(100% - 610px);
        border: none;
        width: 620px;
        padding-top: 10px;
      }
    }
  }
}

@media (min-width: 1921px) and (max-width: 2200px ) {
  .tBalanceitems {
    & .tb-srch {
      &.search-field-expanded {
        //width: calc(100% - 610px);
        border: none;
        width: 620px;
        padding-top: 10px;
      }
    }
  }

  /*.dasboard-container.drawer-small .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-enabled,*/
  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-enabled {
    & .tBalanceitems button.secondary-btn.mat-secondary .mat-button-wrapper .tbh-act-lbl {
      display: none;
    }

    & .tBalanceitems {
      & .tb-srch {
        &.search-field-expanded {
          //width: calc(100% - 600px) !important;
          border: none;
          width: 300px;
          padding-top: 10px;
        }
      }
    }
  }
}

@media (min-width:1920px) {
  .dasboard-container.drawer-small .ntr-panel.ntr-Left-side-menu-desabled.ntr-right-panel-desabled.signOff-disabled {
    & .acc-num-col {
      min-width: 110px;
    }

    & .org-col {
      min-width: 130px;
    }

    & .adjusting-col {
      min-width: 100px;
    }

    & .final-col {
      min-width: 120px;
    }

    & .py1-col {
      min-width: 100px;
    }

    & .change-col {
      min-width: 130px;
    }

    & .py2-col {
      min-width: 100px;
    }

    & .map-num-col {
      min-width: 100px;
    }

    & .grouping-col {
      min-width: 140px;
    }

    & .subGrouping-col {
      min-width: 162px;
    }
  }

  .tb-header {
    & .tBalance-left {
      & .tBalanceitems {
        margin-right: 16px;

        &:last-child {
          margin-right: 0px !important;
        }
      }

      & .tbalance-filter {
        //margin-left: 8px;
      }
    }
  }
}

@media (max-width: 1500px) {
  .dasboard-container.drawer-small .ntr-panel.ntr-Left-side-menu-desabled.ntr-right-panel-desabled.signOff-disabled {
    & .tBalanceitems {
      & .tb-srch {
        &.search-field-expanded {
          //width: calc(100% - 550px) !important;
          border: none;
          width: 630px;
          padding-top: 10px;
        }
      }
    }
  }

  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-desabled.signOff-disabled,
  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-enabled,
  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-disabled,
  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-desabled.signOff-enabled,
  .dasboard-container.drawer-small .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-disabled,
  .dasboard-container.drawer-small .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-desabled.signOff-enabled,
  .dasboard-container.drawer-small .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-enabled {
    & .small-screen {
      display: flex;
    }

    & .large-screen {
      display: none;
    }

    & .tBalance-right {
      & .tBalanceitems {

        & .action-btn {
          top: 3px;
        }
      }
    }
  }

  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-desabled.ntr-right-panel-enabled.signOff-disabled,
  .dasboard-container.drawer-small .ntr-panel.ntr-Left-side-menu-desabled.ntr-right-panel-enabled.signOff-disabled {
    & .tBalanceitems {
      & .tb-srch {
        &.search-field-expanded {
          //width: calc(100% - 600px) !important;
          border: none;
          width: 280px;
          padding-top: 10px;
        }
      }
    }

    & .tBalanceitems button.secondary-btn.mat-secondary .mat-button-wrapper .tbh-act-lbl {
      display: none;
    }
  }

  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-desabled.signOff-disabled .dasboard-container.drawer-small .ntr-panel.ntr-Left-side-menu-desabled.ntr-right-panel-enabled.signOff-disabled, .dasboard-container.drawer-small .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-desabled.signOff-disabled, .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-desabled.ntr-right-panel-desabled.signOff-disabled {
    & .tBalanceitems {
      & .tb-srch {
        &.search-field-expanded {
          //width: calc(100% - 600px) !important;
          border: none;
          width: 300px;
          padding-top: 10px;
        }
      }
    }

    & .tBalanceitems button.secondary-btn.mat-secondary .mat-button-wrapper .tbh-act-lbl {
      display: none;
    }
  }

  .dasboard-container.drawer-small .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-enabled {
    & .tb-tbl-grid {
      min-height: calc(100vh - 363px);
      max-height: calc(100vh - 363px);

      &.showCancelRow {
        min-height: calc(100vh - 400px);
        max-height: calc(100vh - 400px);
      }
    }

    & .client-acceptance, & .independence {
      & .right-section {
        display: block !important;
        width: 100%;
        max-width: 100%;

        & .btns-group {
          margin-left: 20px;
        }
      }
    }

    & .checklist {
      &-row {
        flex-flow: column;

        & .left-section {
          max-width: 100%;
          width: 100%;
        }

        & .right-section {
          width: 100%;

          & .yes-no-group {
            margin-left: 25px;
            padding-right: 0px;
            border-right: 0px;
          }

          & .btns-group {
            margin-left: 20px;
          }
        }
      }

      &-child {
        &-list {
          flex-flow: column;

          & .left-section {
            max-width: 100%;
            width: 100%;
          }

          & .right-section {


            & .yes-no-group {
              padding-right: 0px;
              border-right: 0px;
            }

            & .btns-group {
              margin-left: 0px;
            }
          }
        }
      }

      & .formfield-section {
        & .question {
          width: 100%;
          display: block;

          & .mat-form-field {
            max-width: 130px;
          }
        }

        & .question-four {
          width: 100%;
          display: block;

          & .mat-form-field {
            max-width: 130px;
          }
        }
      }
    }
  }

  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-enabled {
    & .tb-tbl-grid {
      min-height: calc(100vh - 430px);
      max-height: calc(100vh - 430px);

      &.showCancelRow {
        min-height: calc(100vh - 460px);
        max-height: calc(100vh - 460px);
      }
    }
  }
}

@media (min-width: 1921px) {
  .firm-onboarding-top {
    background-attachment: fixed !important;
    background-size: cover !important;
  }
}

@media (min-width: 1501px) and (max-width: 1799px) {

  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-enabled,
  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-disabled,
  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-desabled.signOff-enabled,
  .dasboard-container.drawer-small .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-enabled {
    & .small-screen {
      display: flex;
    }

    & .large-screen {
      display: none;
    }

    & .action-btn {
      top: 3px;
    }
  }

  .dasboard-container.drawer-small .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-disabled,
  .dasboard-container.drawer-small .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-desabled.signOff-enabled {
    & .tBalanceitems button.secondary-btn.mat-secondary .mat-button-wrapper .tbh-act-lbl {
      display: none;
    }

    & .tBalanceitems {
      & .tb-srch {
        &.search-field-expanded {
          //width: calc(100% - 450px) !important;
          border: none;
          width: 300px;
        }
      }
    }
  }

  .dasboard-container.drawer-small .ntr-panel.ntr-Left-side-menu-desabled.ntr-right-panel-desabled.signOff-disabled,
  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-desabled.ntr-right-panel-desabled.signOff-disabled,
  .dasboard-container.drawer-small .ntr-panel.ntr-Left-side-menu-desabled.ntr-right-panel-enabled.signOff-disabled,
  .dasboard-container.drawer-small .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-desabled.signOff-disabled {
    & .tBalanceitems {
      & .tb-srch {
        &.search-field-expanded {
          //width: calc(100% - 490px) !important;
          width: 620px;
          border: none;
        }
      }
    }
  }


  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-desabled.ntr-right-panel-enabled.signOff-disabled,
  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-desabled.signOff-disabled {
    & .tBalanceitems {
      & .tb-srch {
        &.search-field-expanded {
          //width: calc(100% - 640px) !important;
          border: none;
          width: 300px;
        }
      }
    }

    & .tBalanceitems button.secondary-btn.mat-secondary .mat-button-wrapper .tbh-act-lbl {
      display: none;
    }
  }

  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-desabled.ntr-right-panel-desabled.signOff-disabled {
    & .tBalanceitems {
      & .tb-srch {
        &.search-field-expanded {
          //width: calc(100% - 595px) !important;
          border: none;
          width: 620px;
        }
      }
    }
  }
}

@media (min-width: 1441px) and (max-width: 1600px) {

  .dasboard-container.drawer-small .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-enabled {
    & .tb-tbl-grid {
      min-height: calc(100vh - 312px);
      max-height: calc(100vh - 312px);

      &.showCancelRow {
        min-height: calc(100vh - 340px);
        max-height: calc(100vh - 340px);
      }
    }
  }

  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-enabled {
    & .tb-tbl-grid {
      min-height: calc(100vh - 350px);
      max-height: calc(100vh - 350px);

      &.showCancelRow {
        min-height: calc(100vh - 390px);
        max-height: calc(100vh - 390px);
      }
    }
  }

  .dasboard-container.drawer-small .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-enabled {

    & .checklist {
      & .formfield-section {
        & .forminput-field {
          display: block;
          width: 100%;

          & .mat-form-field {
            max-width: 160px;
          }
        }

        & .question-four {
          & .mat-form-field {
            max-width: 130px;
          }
        }
      }
    }

    & .independence, & .client-acceptance, & .client-business, & .planning {
      & .checklist {
        &-row {
          flex-flow: column;

          & .left-section {
            max-width: 100%;
            width: 100%;
          }

          & .right-section {
            //margin-left: 20px;
            & .yes-no-group {
              margin-left: 25px;
              padding-right: 0px;
            }

            & .btns-group {
              margin-left: 20px;
            }
          }
        }

        &-child {
          &-list {
            flex-flow: column;

            & .left-section {
              max-width: 100%;
              width: 100%;
            }

            & .right-section {
              //margin-left: 20px;
              & .yes-no-group {
                padding-right: 0px;
              }

              & .btns-group {
                //margin-left: 0px;
              }
            }
          }
        }
      }
    }

    & .client-business, & .planning {
      & .right-section {
        & .btns-group {
          margin-left: 20px !important;
        }
      }
    }
  }

  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-enabled {

    & .checklist {
      &-row {
        flex-flow: column;

        & .left-section {
          max-width: 100%;
          width: 100%;
        }

        & .right-section {
          width: 100%;
          max-width: 100%;

          & .yes-no-group {
            margin-left: 25px;
            padding-right: 0px;
          }

          & .btns-group {
            margin-left: 0px;
          }
        }
      }

      &-child {
        &-list {
          flex-flow: column;

          & .left-section {
            max-width: 100%;
            width: 100%;
          }

          & .right-section {
            width: 100%;
            max-width: 100%;

            & .yes-no-group {
              padding-right: 0px;
            }

            & .btns-group {
              margin-left: 0px;
            }
          }
        }
      }

      & .formfield-section {
        & .question {
          width: 100%;
          display: block;

          & .mat-form-field {
            max-width: 130px;
          }
        }

        & .question-four {
          width: 100%;
          display: block;

          & .mat-form-field {
            max-width: 130px;
          }
        }
      }
    }

    & .client-business {
      & .right-section {
        margin-left: 20px;
      }
    }

    & .planning {
      & .right-section {
        margin-left: 20px;
      }
    }
  }
}

@media (min-width: 1601px) and (max-width: 1680px) {
  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-enabled {
    & .tb-tbl-grid {
      min-height: calc(100vh - 315px);
      max-height: calc(100vh - 315px);

      &.showCancelRow {
        min-height: calc(100vh - 350px);
        max-height: calc(100vh - 350px);
      }
    }

    & .checklist {
      &-row {
        flex-flow: column;

        & .left-section {
          max-width: 100%;
          width: 100%;
        }

        & .right-section {
          & .yes-no-group {
            margin-left: 25px;
          }
        }
      }

      &-child {
        &-list {
          flex-flow: column;

          & .left-section {
            max-width: 100%;
            width: 100%;
          }

          & .right-section {
            & .yes-no-group {
              //margin-left: 25px;
            }
          }
        }
      }

      & .formfield-section {
        & .forminput-field {
          display: block;
          width: 100%;

          & .mat-form-field {
            max-width: 160px;
          }
        }

        & .question-four {
          & .mat-form-field {
            max-width: 130px;
          }
        }
      }
    }

    & .client-business, & .planning {
      & .right-section {
        & .btns-group {
          margin-left: 20px;
        }
      }
    }
  }
}

@media (min-width: 1681px) and (max-width: 1799px) {
  .dasboard-container.drawer-full .ntr-panel.ntr-Left-side-menu-enabled.ntr-right-panel-enabled.signOff-enabled {
    & .checklist {
      &-row {
        flex-flow: column;

        & .left-section {
          max-width: 100%;
          width: 100%;
        }

        & .right-section {
          & .yes-no-group {
            margin-left: 25px;
          }
        }
      }

      &-child {
        &-list {
          flex-flow: column;

          & .left-section {
            max-width: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}

.client-acceptance, .independence, .client-business, .planning {
  & .quesn-save {
    & .float-right {
      margin-right: 0px !important;
    }
  }
}

.grid-icon:hover {
  color: $primary-color;
}
button{
  &:disabled{
    .grid-icon:hover {
      color:#A7C4CB;
    }
  }
}
.disable-icon {
  opacity: 0.8;

  &:hover {
    color: $action-icons-color;
  }
}

.atag-color {
  color: $blue-v2-color;
}

.drag-items {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-start;
  padding: 6px 0;
  background-color: #fff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.16);
  border: 1px solid #BBBBBB;
  position: relative;

  &:before {
    content: '';
    width: 3px;
    background-color: $primary-color;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  & h6, & p {
    margin: 0;
    line-height: 1;
  }

  &-left {
    width: 24px;
    display: flex;
    align-items: center;
  }

  &-right {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
  }

  & .drad-doc-title {
    margin-bottom: 2px;
  }

  & .small {
    color: #9A9A9A;
  }

  & .drag-ico {
    padding: 0;

    & .material-icons {
      width: 24px;
      line-height: 1;
      color: #ccc;
      font-size: 18px;
    }
  }
}

.toggle-slide {
  & .toggle-slide-section {
    height: auto;

    & .mat-slide-toggle {
      margin-top: 0;
    }
  }
}

.doc-request-form {
  padding: 16px;
  display: flex;
  flex-flow: column nowrap;
}

.yesno-btn {
  padding: 0px 29px !important;
}

.custom-mat-accordion {
  width: 100%;
  display: inline-block;
  background-color: #fdfdfd;

  & .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body .mat-expansion-panel .mat-expansion-panel-header {
    padding-left: 0px;
    padding-right: 0px;

    &:hover {
      background-color: #f2f2f2;
    }

    & a {
      &:hover {
        background-color: transparent;
      }
    }

    & .leadsheet-label {
      font-weight: normal;
    }
  }

  & .mat-accordion {
    & > .mat-expansion-panel {
      margin-bottom: 5px !important;
    }

    & .mat-expansion-panel {
      box-shadow: none !important;
      background-color: #FCFCFC;
      border-top: 1px solid #dee2e6 !important;
      border-bottom: 1px solid #dee2e6 !important;
      /*&.mat-expanded {
        & .mat-expansion-panel-content {
            padding-bottom: 12px;
            padding-top: 12px;
        }
      }*/
      & .mat-expansion-panel-content {
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0;

        & .mat-expansion-panel-body {
          & .mat-expansion-panel {
            & .mat-expansion-panel-header {
              &::before {
                position: relative;
                left: 25px;
              }

              & a {
                padding-left: 25px;

                &:hover {
                  background-color: transparent;
                }
              }

              & .mat-expansion-indicator {
                left: 23px;
              }
            }

            & .mat-expansion-panel-content {
              & .mat-expansion-panel-body {
                & .mat-expansion-panel {
                  & .mat-expansion-panel-header {
                    &::before {
                      position: relative;
                      left: 50px;
                    }

                    & a {
                      padding-left: 49px;
                    }

                    & .mat-expansion-indicator {
                      left: 45px;
                    }
                  }

                  & .mat-expansion-panel-body {
                    & ul li a {
                      padding-left: 98px;

                      &:hover {
                        background-color: transparent;
                      }
                    }
                  }
                }

                & > ul > li {
                  border-top: 1px solid #dee2e6 !important;

                  & a {
                    padding-left: 72px;

                    &:hover {
                      background-color: transparent;
                    }
                  }
                }
              }
            }
          }

          & > ul > li {
            &.add-folder {
              & a {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: flex-start;
                line-height: normal;

                & .leadsheet-label {
                  position: static;
                }
              }
            }

            & > a {
              padding-left: 49px;
            }
          }
        }


        & .mat-expansion-panel-body {
          & > ul {
            & > li {
              & > a {
                border-top: 1px solid #dee2e6 !important;
              }

              & > ul {
                & > li {
                  border-bottom: 1px solid #dee2e6 !important;

                  & > .mat-expansion-panel {
                    border-bottom: none !important;
                  }
                }
              }
            }
          }
        }
      }

      & .mat-expansion-panel {
        border-bottom: none !important;
      }


      &:first-of-type {
        border-radius: 0px;
      }

      &:last-of-type {
        border-radius: 0px;
      }

      &-spacing {
        margin: 0px;
      }

      &-header {
        height: 38px !important;
        max-height: 38px !important;
        padding-left: 8px;
        padding-right: 0;
        position: relative;

        &:hover {
          background-color: #f2f2f2;
        }

        & .active-header {
          display: none;
          content: "";
          position: absolute;
          left: -25px;
          top: -5px;
          width: 3px;
          height: 36px;
          background-color: rgba(252, 207, 92, 1);
        }

        &.mat-expanded {
          & .active-header {
            display: block;
          }
        }

        &::before {
          border: none !important;
          content: "\f0da";
          font-family: 'Font Awesome 6 Pro' !important;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.3s ease;
          opacity: .5;
        }

        & .mat-expansion-indicator {
          display: none;
          position: relative;
          position: absolute;
          left: 3px;
          transform: rotate(0deg) !important;

          &::after {
            border: none !important;
            content: "\f0da";
          font-family: 'Font Awesome 6 Pro' !important;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: rotate(0deg) !important;
            opacity: .5;
          }
        }

        & .mat-content {
          position: relative;
          overflow: visible;
        }

        &-title {
          font-weight: 600;
          line-height: 26px;
          margin-right: 0px !important;
          width: 100%;
        }

        &.mat-expanded {
          position: relative;
          background-color: #FAFAFA;

          & .mat-expansion-indicator {
            display: none !important;
            transform: rotate(45deg) !important;

            &::after {
              transform: rotate(0deg) !important;
            }
          }

          &::before {
            transform: rotate(45deg) !important;
          }
        }
      }

      &-body {
        padding: 0px;

        & ul {
          margin: 0;
          padding: 0px;
          list-style: none;

          & li {
            display: block;
            list-style: none;

            &:hover {
              background-color: #f2f2f2;
            }

            &.active {
              position: relative;
              background-color: transparent;

              &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 3px;
                height: 36px;
                background-color: rgba(252, 207, 92, 1);
              }
            }

            & a {
              display: block;
              font-size: 14px;
              color: #212121;
              padding-left: 30px;
              padding-right: 16px;
              height: 38px;
              text-decoration: none;

              & .co, & .fs {
                @extend %common-ico-setting;
              }

              &:hover {
                background-color: transparent;
              }

              & .leadsheet-label {
                position: relative;
                top: 9px;
              }
            }
          }
        }
      }
    }
  }
}

.custom-row, .file-row, .documents-list-row {
  display: inline-block;
  width: 100%;
}

.file-row {
  & .file-ico, & .file-label {
    display: block;
    float: left;
  }

  & .file-ico {
    margin-right: 8px;
  }
}

.documents-list-row {
  & .doc-request {
    padding-top: 5px;
    padding-bottom: 5px;

    & .doc-stat-ico {
      margin-left: 0;
      margin-right: 0;

      button {
        padding: 0 0 0 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.doc-request {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 15px 12px 0;

  &.file-rquest {
    height: 44px;
    border-bottom: 1px solid #dee2e6;

    &:last-child {
      border-bottom: none;
    }
  }

  &.doc-pending {
    border-bottom: 1px solid #dee2e6;

    &:last-child {
      border-bottom: none;
    }
  }

  &.recieved-docs {
    margin-top: 8px;
    border-bottom: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;

    &.drag-enabled {
      background-color: #F5FCFF;
      opacity: 0.5;
    }

    & .doc-stat-ico i.material-icons {
      color: #ccc;
      font-size: 18px;
    }

    &:first-child {
      border-top: 1px solid #dee2e6;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &.referenced-in {
    padding-left: 16px;

    & .doc-tittle {
      max-width: 100%;
      width: 100%;

      & .documents-list-row {
        & .doc-request {
          padding-right: 0;

          & .doc-tittle {
            & .file-row {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-flow: row nowrap;
              padding: 8px;
              border: 1px solid $gray-v11-color;
              background-color: #F2F9FC;

              a.referenced-count.mat-icon-button {
                border: 1px solid $gray-v11-color;
                padding: 2px 10px;
                width: auto !important;
                height: auto !important;
                border-radius: 45% !important;
                color: $black-v3-color;
              }
            }
          }
        }
      }
    }
  }

  &:last-child {
    border-bottom: none;
  }

  .doc-stat-ico, .doc-tittle {
    /*display: inline-block;*/
    h6 {
      margin: 0px;
    }

    p {
      margin: 0px 0 0 0;
      font-size: 12px;
    }

    .small {
      color: $gray-color;
      font-size: 100%;

      & .saparator {
        padding: 0 6px;
      }
    }
  }

  .doc-stat-ico {
    width: 24px;
    height: 24px;
    text-align: center;
    margin-left: 8px;
    margin-right: 6px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    & .request-btn {
      padding: 0;
      line-height: 1;
      color: $primary-color;
      width: 24px;
      height: 24px;

      & .material-icons {
        width: 24px;
        height: 24px;
        font-size: 24px;
        line-height: 1;
      }
    }

    img {
      border: none;
    }
  }

  .doc-tittle {
    max-width: calc(100% - 30px);
    width: calc(100% - 30px);

    a {
      color: rgba(0, 0, 0, 0.87);
    }

    & .content-top {
      height: auto;
      display: flex;
      overflow: hidden;
      min-height: 24px;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;

      & .float-left, & .float-right {
        height: 16px;
        max-height: 16px;
      }
    }

    .doc-req-filter-btn {
      position: relative;
      top: -5px;
    }

    & .mat-icon-button {
      width: 16px;
      height: 16px;
      line-height: 1;

      & .mat-icon {
        width: 16px;
        height: 16px;
        font-size: 18px;
        line-height: 1;
      }
    }
  }
}
/*checklist*/
.checklist-button {
  top: -1px !important;
  padding: 0 !important;
  min-width: 45px !important;
  line-height: 2px !important;
  margin-right: 10px !important;

  & .comment-icon, & .notes-icon {
    line-height: 1 !important;
    color: #9E9E9E;
  }
}

.checklist {
  border: 1px solid #E0E0E0;
  padding: 12px 12px;
  font-weight: 500;
  margin-top: 20px;

  &-no {
    margin-right: 12px;
    float: left;
    margin-top: 6px;
  }

  &-text {
    margin-right: 17px;
    width: 100%;
    display: inline-block;
    word-break: break-all;
    margin-top: 6px;
  }

  & .radioption-No {
    margin-right: 16px;

    & .mat-radio-label {
      & .mat-radio-container {
        & .mat-radio-outer-circle {
          border-color: $gray-v26-color;
        }
      }
    }
  }

  & .vline {
    border-left: 1px solid $gray-v11-color;
    height: 26px;
    display: inline-block;
    position: relative;
    margin: 0 30px;
  }

  & .checklist-row, & .checklist-child-list {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
  }

  & .left-section {
    max-width: calc(100% - 230px);
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  & .right-section {
    position: relative;
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-start;
    min-width: 0;
    max-width: 216px;

    & .yes-no-group {
      border-right: 1px solid #d9d9d9;
      padding-right: 16px;
      height: 32px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      & label.mat-radio-label {
        margin-bottom: 0px;
      }
    }

    & .btns-group {
      margin-left: 10px;
      display: flex;
      flex-flow: row nowrap;

      & button.checklist-button.mat-button {
        width: 32px;
        height: 32px;
        min-width: 0 !important;

        &.no-marg {
          margin-right: 0px !important;
        }
      }
    }
  }



  & .comment-section {
    width: 100%;
    margin-top: 3px;
    padding-left: 23px;
  }
}

.add-question {
  // border: 1px solid $gray-v11-color !important;
  margin: 20px 0 20px !important;
  font-size: 16px !important;
  padding: 0 12px !important;
  color: $black-v3-color;
  font-weight: 600;

  & .add {
    color: $primary-color;
  }
}

.add-ques-no {
  margin-top: 9px;
}

.add-ques-row {
  width: 96% !important;
  margin-right: 0px !important;

  & .add-ques-comment {
    margin-top: -5px !important;
    padding-left: 0px !important;
  }
}

.box-active {
  border: 1px solid #FBC02D;
  box-shadow: 0px 0px 6px 0px #FBC02D4D;
}

.comment-active {
  color: #FBC02D !important;
}

.doc-item {
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 0 10px;
  height: 40px;
  border: 1px solid #E0E0E0;
  margin: 10px 0px 0px 10px;

  &.doc-with-details {
    display: flex;
    justify-content: flex-start;
    height: auto;

    & .doc-with-header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 0;
      border-bottom: 1px solid #E0E0E0;

      & .mat-icon-button {
        width: 24px;
        height: 24px;
        line-height: 1;
        color: #9e9e9e;

        &.activeMatIco {
          color: #FF5353;
        }
      }
    }

    & .doc-details {
      margin: 0px;

      & .details-list {
        padding-bottom: 6px;

        &-body .custom-row {
          padding: 6px 0 0 0;
        }
      }
    }
  }

  &.size-auto {
    width: auto;
  }

  &.size-full {
    width: 100%
  }

  & .pdf-icon {
    top: -1px;
    position: relative;
    margin-right: 8px;
  }
}

.ok-button {
  padding: 0px 31px !important;
}

.add-button {
  padding: 0px 27px !important;
}

.cancel-button {
  padding: 0px 18px !important;
}

.close-button {
  padding: 0px 22px !important;
}

.unap-yes-no {
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
}

.comment-icon.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  font-size: 11px;
  width: 16px;
  height: 16px;
  top: -7px;
  right: -7px;
  line-height: 18px;
}

.comment-section.mat-form-field-appearance-legacy .mat-form-field-underline,
.comment-section-list.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #E0E0E0;
  background-image: none !important;
}
/* end of checklist*/
/*Chat UI*/
.chat-box {
  display: block;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  max-height: calc(100vh - 285px);
  height: calc(100vh - 285px);
  width: 100%;
  overflow-y: auto;

  & ul, & li {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  & li {
    position: relative;
    margin-bottom: 16px;

    &.replied-message {
      &:before {
        position: absolute;
        content: '';
        width: 1px;
        top: 24px;
        bottom: -16px;
        left: 12px;
        background-color: #E0E0E0;
      }

      &-end {
        border-bottom: 1px dashed #ccc;
        padding-bottom: 16px;
      }
    }
  }

  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    height: 24px;
  }

  & .chatter {
    &-short-name, &-full-name, &-message-time {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
    }

    &-short-name {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      color: #fff;
      font-size: 12px;
      text-transform: uppercase;
      line-height: 1;
      justify-content: center;
      margin-right: 12px;
    }

    &-full-name {
      max-width: calc(100% - 95px);

      & h6 {
        margin: 0;
        font-size: 14px;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      justify-content: flex-start;
      margin-right: 8px;
    }

    &-message-time {
      justify-content: flex-start;
      font-size: 14px;
      color: #9A9A9A;

      p {
        margin: 0;
        line-height: 1;
      }
    }

    &-message {
      margin-left: 38px;

      p {
        margin: 0;
        font-size: 14px;
      }

      &-reply {
        margin: 8px 0 0 38px;

        a {
          font-size: 14px;
          font-weight: 600;
          color: $black-v3-color;
        }
      }
    }
  }
}

.chat-color {
  &-1 {
    background-color: #2A35BA;
  }

  &-2 {
    background-color: #2ABAA2;
  }
}

.chat-form {
  display: flex;
  flex-flow: column nowrap;
  padding-right: 16px;

  &-actions {
    display: flex;
    justify-content: flex-end;

    & button {
      margin-top: 16px;
      margin-bottom: 0px;
    }
  }
}

.referenced-items {
  display: block;
  padding: 16px 0;
  min-width: 215px;

  h6 {
    color: $gray-color;
    padding-left: 16px;
    font-weight: 400;
  }
}

.no-rquested-files {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 68px;
  margin: 15px;
  border: 1px dashed $gray-v6-color;
  border-radius: 5px;

  h6 {
    margin: 0;
    color: #555;
  }
}

.request-procedure-items {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  position: relative;

  & button.mat-icon-button {
    position: absolute;
    right: 0px;
    top: -4px;
    width: 24px;
    height: 24px;
    line-height: 1;
  }
}

.request-procedures-grid {
  min-width: 210px;
  padding: 0 !important;
  background-color: #fff;

  p {
    margin: 0 !important;
  }

  th.mat-header-cell {
    background-color: #fff !important;
  }

  tbody tr {
    &.mat-row {
      &:hover, &.selected {
        background-color: rgba(0, 0, 0, .05)
      }

      & td.mat-cell {
        background-color: transparent;
        padding-left: 15px;
      }
    }
  }

  & td.mat-column-description, & td.mat-column-description p {
    @extend %ellipsis;
    max-width: 150px;
  }

  & td.mat-column-description {
    width: 150px;
    max-width: 150px;
  }
}

.mat-menu-panel.request-procedures-matMenu,
.mat-mdc-menu-panel.request-procedures-matMenu {
  max-width: none;
  padding: 0px;
}

app-import-trialbalance > .import-partner > .import-partner-heading {
  padding-left: 24px;
}

.primary-dark-color {
  color: $primary-color !important;

  &:disabled {
    color: #9E9E9E !important;
  }
}

.primary-icon-color {
  color: $primary-icon-color !important;

  &:disabled {
    color: #9E9E9E !important;
  }
}

.danger-color {
  color: #D93540;
}

.red-color {
  &-v1 {
    color: #E85757;
  }
}

.blue-color {
  &-v1 {
    color: #7C94F2;
  }
}

.voilet-color {
  &-v1 {
    color: #918BE6;
  }
}

.bg-color {
  &-1 {
    background-color: #F4E8FC !important;
    color: #8738BB;
  }

  &-2 {
    background-color: #34D4D4;
  }

  &-3 {
    background-color: #4CA472;
  }

  &-4 {
    background-color: #4C6FA4;
  }
}

.cust-badge.mat-badge.mat-badge-medium .mat-badge-content {
  width: 16px;
  padding: 0;
  height: 16px;
  line-height: 16px;
  right: -6px;
  top: -1px;
  font-size: 12px;
  background-color: #E21212 !important;
  color: #FFFFFF !important;
  border-radius: 50% !important;
  font-family: 'Roboto',regular !important;
  font-weight: 400 !important;
}

.notification {
  position: relative;

  & .noti-badge {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #D93540;
    position: absolute;

    &.top-right {
      top: -2px;
      right: -2px;
    }
  }
}

.noti-badge {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #D93540;
  position: absolute;

  &.top-right {
    top: 2px;
    right: 0;
  }
}


.notification-in {
  position: relative;

  & .noti-badge {
    display: inline-block;
  }
}

.size {
  &-16 {
    font-size: 16px !important;
    line-height: 1;
  }

  &-17 {
    font-size: 17px !important;
    line-height: 1;
  }

  &-18 {
    font-size: 18px !important;
    line-height: 1;
  }

  &-20 {
    font-size: 20px !important;
    line-height: 1;
  }
}

.dropdown-tbl {
  max-width: 350px;

  &.adjustment-dropDown-tbl {
    max-width: 540px;

    & .dropdown-tbl-body {
      overflow-x: auto;

      & .tbl-grid.mat-table tbody td.mat-column-accountname {
        max-width: 150px;
      }
    }
  }

  &-header {
    border-bottom: 1px solid rgba(0,0,0,.12);

    & .mat-input-element {
      text-align: left;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  &-body {
    padding-left: 0px;
    padding-right: 0px;
    max-height: 280px;
    overflow-y: auto;
    overflow-x: hidden;

    & .tbl-grid {
      &.mat-table {
        width: 100%;

        & tr.mat-header-row, & tr.mat-row {
          height: 34px !important;
        }

        & th {
          background-color: $white-color;
        }

        & th, & td {
          //background-color:transparent !important;
          text-align: left;
          padding: 4px 8px !important;
        }

        & tbody {
          & tr, & td {
            border-bottom: none;
          }

          & tr {
            &:hover {
              td {
                background-color: rgba(0, 0, 0, 0.04) !important;
              }
            }
          }

          & td {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

.mat-menu-panel.custom-dropdown-container,
.mat-mdc-menu-panel.custom-dropdown-container {
  max-width: none;
}
/*templates*/
.preview {
  &-container {
    @extend %flex-box;
    @extend %flex-box-left;
    flex-flow: row wrap;
  }

  &-item {
    /*background-color: rgba(0,0,0,0.24);*/
    @extend %flex-box;
    @extend %flex-box-center;
    flex-flow: column nowrap;
    margin-right: 30px;
    margin-bottom: 30px;


    &:last-child {
      margin-right: 0px;
    }

    &-body {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 1;
      border: 1px solid $gray-v2-color;

      &:hover {
        & .preview-hover-container {
          display: flex;
        }
      }

      &.shadow {
        padding: 16px;
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.75);
      }
    }

    &-details {
      display: flex;
      flex-flow: column;
      align-self: flex-start;

      & h5 {
        margin: 0 0 3px 0;
        line-height: 1;
        font-size: 16px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
        margin-top: 16px;
      }
    }

    &.potrait {
      width: auto;

      & .preview-item-body {
        height: 295px;
      }
    }

    &.landscape {
      max-width: 732px;
      width: auto;
      margin-top: 22px;

      & .preview-item-body {
        height: auto;
      }

      & .preview-hover-item {
        width: 28px;
        height: 24px;

        & .material-icons {
          font-size: 16px;
          line-height: 16px;
        }

        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    &.rectangle {
      width: 376px;

      & .preview-item-body {
        height: 80px;
      }
    }

    &.square {
      width: 220px;
      display: inline-block;

      & .preview-item-body {
        height: 220px;
        border: 1px solid lightgrey;
      }

      & .preview-item-details {
        & i.material-icons {
          font-size: 13px;
        }
      }
    }
  }

  &-hover {
    &-container {
      @extend %flex-box;
      @extend %flex-box-center;
      display: none;
      background-color: rgba(0,0,0,0.24);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }

    &-item {
      background-color: $white-color;
      width: 40px;
      height: 50px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;

      & .material-icons {
        color: $gray-color;
      }

      &:first-child {
        -webkit-border-top-left-radius: 15px;
        -webkit-border-bottom-left-radius: 15px;
        -moz-border-radius-topleft: 15px;
        -moz-border-radius-bottomleft: 15px;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }

      &:last-child {
        -webkit-border-top-right-radius: 15px;
        -webkit-border-bottom-right-radius: 15px;
        -moz-border-radius-topright: 15px;
        -moz-border-radius-bottomright: 15px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }
}

.mat-btn-custom {
  min-width: 0 !important;

  &-24 {
    padding: 0px !important;
    width: 24px !important;
    height: 24px !important;
    min-width: 0 !important;
    line-height: 24px !important;

    &:disabled {
      background-color: transparent !important;
    }
  }
}

button.sub-header-btn.mat-raised-button.mat-primary {
  padding: 6px 16px;
  margin-top: 0;
  height: 32px;
  border-radius: 16px;
  color: $secondary-color;
  display: inline-block;
  outline: none;
  border: none;
  box-shadow: none;
  border-radius: 0px;

  & .mat-button-wrapper {
    display: inline-block;
    width: 100%;
    line-height: 32px;
  }

  & .ico {
    display: inline-block;
    margin-right: 5px;
    vertical-align: top;
    line-height: 18px;

    &.material-icons {
      font-size: 20px;
      font-weight: 100;
    }
  }

  & .label {
    font-weight: 500;
    display: inline-block;
    vertical-align: top;
    line-height: 18px;
  }
}

.directory {
  & ul {
    margin: 0;
    padding: 0;
    display: block;
    list-style: none;

    & li {
      background-color: $white-color;
      display: block;
      margin: 0 0 16px 0;
      padding: 0;
      list-style: none;
      height: 76px;
      border: 1px solid $gray-v11-color;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;

      &.active {
        background-color: $ntr-menu-active;
        border-color: $primary-v6-color;
      }

      a {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        width: 100%;
        height: 100%;
        text-decoration: none;

        & .dir {
          &-label {
            font-size: 15px;
            font-weight: 600;
            color: $secondary-color;
            max-width: calc(100% - 50px);
          }

          &-count {
            width: 34px;
            height: 34px;
            border: 1px solid $ntr-menu-active;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: $secondary-color;
          }
        }
      }
    }
  }
}

.pd-dialog-delete {
  padding: 0px 12px;
  min-width: 352px;
  width: 352px;

  & .dialogboxheader {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    & .header-title {
      display: block;
      margin: 0px auto;
      min-width: 100%;

      & .warning-msg {
        color: $warning-icon-color;
        margin-right: 5px;
      }
    }

    & .transparent-btn {
      position: relative;
      bottom: 14px;
      right: 7px;

      & .clear-icon {
        font-size: 24px;
        color: $gray-color;
      }
    }
  }

  & .delete-content {
    padding: 0px 0px 14px 0px;
  }

  &-actions {
    width: 100%;
    padding: 0px 24px;
  }

  & .no-btn {
    border: 1px solid $gray-v2-color;
  }

  & .no-btn, & .yes-btn {
    width: 90px !important;
    height: 30px !important;
  }

  & .mat-dialog-actions {
    display: block;

    & button {
      font-size: 16px;
    }
  }

  & .mat-dialog-title {
    font-size: 20px;
    display: flex;

    & .warn-ico {
      margin-right: 6px;
    }
  }

  & .dialogbox {
    text-align: center;
    font-size: 14px;
    color: black;
    line-height: 20px;
    font-family: 'Roboto', sans-serif !important;
  }
}

.files {
  display: flex;
  flex-flow: column nowrap;

  &-label {
    margin-bottom: 16px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    h6 {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      color: $secondary-color;
    }
  }

  &-upload {
    & .file {
      width: 100%;
      height: 112px;
      margin-bottom: 24px;
      background-color: #fff;
      border-radius: 5px;
      border: 1px dashed $gray-v6-color;
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      & .file-custom {
        display: inline-block;
      }

      & .upload-ico {
        font-size: 40px;
        line-height: 1;
        margin-bottom: 6px;
        color: $gray-v9-color;
      }
    }
  }
}

.files-list, .requested-files-list {
  & ul {
    margin: 0;
    padding: 0;
    display: block;
    list-style: none;

    & li {
      position: relative;
      background-color: $white-color;
      display: block;
      margin: 0 0 16px 0;
      padding: 0;
      list-style: none;
      height: 54px;
      overflow: hidden;
      border: 1px solid $gray-v11-color;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;

      &.active {
        background-color: $ntr-menu-active;
        border-color: $primary-v6-color;
      }

      a {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        padding: 16px;
        width: 100%;
        height: 100%;
        text-decoration: none;

        & .file-ico {
          margin-right: 16px;
        }

        & .file-label {
          margin: 0px;
          color: $secondary-color;
          font-size: 14px;
        }
      }
    }
  }
}

.uploaded-files {
  &-label {
    margin-top: 32px;
  }

  &-list {
    & > ul {
      margin: 0;
      padding: 0;
      display: block;
      list-style: none;

      & > li {
        position: relative;
        background-color: $white-color;
        display: block;
        margin: 0 0 16px 0;
        padding: 8px 0;
        list-style: none;
        min-height: 54px;
        height: auto;
        overflow: hidden;
        border: 1px solid $gray-v11-color;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;

        &.active {
          background-color: $ntr-menu-active;
          border-color: $primary-v6-color;
        }

        & .leadsheet {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 0px 16px 0 16px;
          margin-bottom: 8px;
          width: 100%;
          text-decoration: none;

          & .leadsheet {
            &-label {
              margin: 0px;
              color: $secondary-color;
              font-size: 14px;
              font-weight: 600;
            }

            &-comment-btn {
              background-color: transparent;
              width: 24px;
            }
          }
        }

        & > ul, & li {
          margin: 0;
          padding: 0;
          display: block;
          list-style: none;
        }

        & > ul > li {
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0px;
          }

          & a {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            padding: 0 16px;
            width: 100%;
            height: 100%;
            text-decoration: none;

            & .file-ico {
              margin-right: 16px;
            }

            & .file-label {
              margin: 0px;
              color: $secondary-color;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.file-progress-bar {
  width: 100%;
  align-self: unset;
  display: flex;
  flex-flow: column;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;

  & .progress-status-value {
    display: block;
  }

  & .mat-progress-bar {
    width: 100%;
    display: block;
    height: 3px;
  }

  & .mat-progress-bar-buffer, & .mat-progress-bar-background {
    background-color: $white-color;

    &.mat-progress-bar-element {
      height: 3px;
      margin-top: 0px !important;
    }
  }

  & .mat-progress-bar-fill {
    height: 3px !important;

    &:after {
      height: 3px;
      background-color: $primary-v6-color;
    }
  }
}

.files-details-view {
  width: 264px;
  padding: 0;
}

.files-recieved {
  padding-right: 16px;
}

.files-details-view {
  padding: 0;
}

.files-recieved, .files-details-view {
  margin-top: 16px;

  & > p {
    font-size: 12px;
    text-transform: uppercase;
    color: $gray-color;
    margin: 0 0 6px 0;
  }

  & ul, & li {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  & li {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  & .doc-item {
    margin: 0px;
  }
}

.doc-details {
  margin-top: 16px;

  & ul, & li {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
/*details list*/
.details-list {
  padding-bottom: 20px;

  &-header {
    margin-bottom: 10px;
  }

  &-body {
    &-left {
      width: 120px;
      display: inline-block;
    }

    &-right {
      display: inline-block;

      & .history-icon {
        margin-left: 5px;
        font-size: 21px;
        opacity: 0.5;
      }
    }

    & .text-section {
      margin-bottom: 0px;
      display: inline-flex;
    }

    & .custom-row {
      padding: 5px 0;
    }
  }
}
/* end of details list*/
.client-actions {
  button {
    background-color: transparent;
    border: none;
    padding: 6px;
    height: 30px;
    margin-right: 8px;

    &:last-child {
      margin-right: 0px;
    }

    & .material-icons {
      font-size: 18px;
      line-height: 1;
      color: $gray-color;
    }
  }
}

.requested-files-list {
  & > ul {
    & > li {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      & > .doc-request {
        padding: 0px 15px 0px 0;
        display: flex;
        flex-flow: row nowrap;
        width: 100%;

        & > .doc-stat-ico {
          align-items: flex-start;
        }

        & > .doc-tittle {
          & > .content-top {

            & .file-detais {
              p {
                margin: 0;
                color: $gray-color;
              }
            }

            & .file-comment {
              & button.mat-icon-button {
                width: 24px;
                height: 24px;
                line-height: 1;

                & .mat-icon {
                  width: 24px;
                  height: 24px;
                  font-size: 22px;
                  line-height: 1;
                  color: #a2a2a2;
                }
              }
            }
          }
        }
      }

      & a {
        padding: 0px;
      }
    }
  }
}


button.comment-btn.mat-icon-button {
  width: 24px;
  height: 24px;
  line-height: 1;

  & .mat-icon {
    width: 24px;
    height: 24px;
    font-size: 22px;
    line-height: 1;
    color: #a2a2a2;
  }
}

// .reference-documents-details, .document-tabs {
//   & .mat-tab-label {
//     height: 32px;
//   }
// }

// .document-tabs {

//   .mat-tab-labels {
//     display: flex;
//     flex-flow: row nowrap;
//     flex-grow: 1;
//     width: 100%;

//     & .mat-tab-label {
//       padding: 0 12px;
//       min-width: 0;
//       width: 50%;
//       border-bottom: 3px solid transparent;

//       &-active {
//         border-color: $primary-secondary-color;

//         & .mat-tab-label-content {
//           width: 100%;
//           height: 100%;

//           & > span {
//             width: 100%;
//             height: 100%;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//           }
//         }
//       }
//     }
//   }
// }

.vertical-nav {
  & ul {
    margin: 0;
    padding: 0;
    display: block;
    list-style: none;

    & li {
      position: relative;
      background-color: $white-color;
      display: block;
      margin: 0 0 0px 0;
      padding: 0;
      list-style: none;
      height: 48px;
      overflow: hidden;
      /*border-bottom: 1px solid $gray-v11-color;*/
      border: none;

      &.active {
        font-weight: 700;

        & a {
          background-color: $gray-v3-color;
        }
      }

      a {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        padding: 16px;
        width: 100%;
        height: 100%;
        text-decoration: none;
        color: $black-v3-color;

        &:hover {
          background-color: $gray-v3-color;
        }

        & .ico {
          margin-right: 8px;
        }

        & .label {
          margin: 0px;
          color: $secondary-color;
          font-size: 14px;
        }
      }
    }
  }
}

.header-title {
  @extend %flex-box;
  @extend %flex-box-left;

  & .short-hand {
    @extend %flex-box;
    @extend %flex-box-center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 20px;
    font-weight: bold;
    margin-right: 12px;
    position: relative;
  }

  & .name {
    @extend %flex-box;
    @extend %flex-box-left;
    font-size: 14px;
    font-weight: 600;
  }
}

.backup {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  p {
    text-align: center;
  }

  & input[type="file"] {
    width: 50px;
    height: 20px;
    cursor: pointer;
  }

  & i .material-icons {
    color: $gray-v15-color;
  }
}


.col-space {
  margin-right: 16px;

  &:last-child {
    margin-right: 0px;
  }
}

.engagement-save {
  position: relative;
  top: -14px;

  button {
    box-shadow: none !important;
  }
}

.dashed-border {
  border: 1px dashed $white-color;
  position: relative;
  padding: 2px 3px 2px 2px;
  min-height: 32px;
  height: auto;
  overflow: hidden;
  display: block;
  // align-items: center;
  // justify-content: flex-start;
  &.sticky-items {
    border: 1px dashed $gray-v13-color;
  }

  &.full-content {
    display: block;
    padding: 0px !important;
  }

  & .place-holder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: $gray-color;
  }

  & p {
    padding: 0px;
  }

  & .text-flx {
    display: flex;
  }

  &.hovered {
    padding-right: 0px;
  }

  &:hover, &.hovered {
    border: 1px dashed $gray-v13-color;

    .edit {
      display: inline-block;
    }
  }

  &.signature-container {
    &:hover {
      & .edit {
        display: none !important;
      }
    }
  }

  .edit {
    /*position: absolute;
    top: 2px;
    right: 0px;*/
    width: 24px;
    height: 24px;
    padding: 0;
    display: none;
    cursor: pointer;

    .material-icons {
      font-size: 20px;
      color: $black-color;
    }

    &.enabled-field {
      & .material-icons {
        color: $primary-color;
      }
    }
  }
}

.name-field {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;

  & .editable-item {
    background-color: transparent;
    margin: 0px;
    font-size: 20px;
    font-weight: 500;
    color: #212529;
    font-family: Roboto,Medium;
    line-height: 1;
    border: none;
  }

  & .header-name {
    width: 350px;
  }

  &.edit-mat-field {
    & button.icon {
      position: relative;
      top: 20px;
    }
  }

  & h3.text-flx {
    height: 24px;

    & .plain-text {
      padding-right: 20px;
      padding-top: 2px;
    }

    & button.icon {
      & > .material-icons {
        font-size: 22px !important;
      }
    }
  }

  & button.icon {
    color: $primary-v6-color;
    width: 24px;
    height: 24px;
    margin-right: 8px;

    & > .material-icons {
      font-size: 18px !important;
      margin: 0px;
    }
  }
}

.team-member {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row nowrap;
  margin-right: 24px;

  &:last-child {
    margin-right: 0px;
  }

  &-short-name {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  &-full-name {
    @extend %ellipsis;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 176px;
    min-width: 176px;

    & h6, & p {
      margin: 0px;
    }

    & h6 {
      font-size: 14px;
    }

    & p {
      font-size: 12px;
    }
  }

  &-short-name {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 14px;
    color: $white-color;
    text-transform: uppercase;
    line-height: 1;
    justify-content: center;
    margin-right: 12px;
    //border: 1px solid #7BA2A2;
  }

  &-email, &-phone {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: row nowrap;

    & .user-name {
      @extend %ellipsis;
      margin: 0px;
      padding: 0px;
      color: $secondary-color;
      max-width: 176px;
      min-width: 176px;
      font-size: 14px;
      line-height: 1;
    }

    & .ico {
      color: $gray-color;
      margin-right: 12px;
      font-size: 16px;
      line-height: 1;
    }
  }
}

.connected-book {
  background-color: #BBF1BA !important;
}

.dark-yellow {
  color: $dark-yellow;
}

.team {
  &-details {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 676px;
    max-width: 676px;
    margin-right: 62px;

    &:last-child {
      margin-right: 0px;
    }

    &-row {
      display: flex;
      flex-flow: row nowrap;
    }

    & .team-title {
      margin: 18px 0;
      font-size: 14px;
      font-weight: 600;
    }

    & ul {
      display: block;
      list-style: none;
      margin: 0 0 16px 0;
      padding: 0;

      & li {
        display: block;
        list-style: none;
        margin: 0;
        padding: 16px 0;
        border-top: 1px solid $gray-v2-color;

        &:last-child {
          border-bottom: 1px solid $gray-v2-color;
        }
      }
    }

    &-container {
      align-items: flex-start !important;
    }
  }
}

.more-li {
  position: relative;

  & .more-item {
    display: none;
    cursor: pointer;
    position: absolute;
    right: 0;
  }

  &:hover, &.hovered {
    & .more-item {
      display: inline-block;
    }
  }

  & .name-field {
    width: 100%;

    & .text-field {
      width: 100%;
      max-width: calc(100% - 22px);
    }

    & button.icon {
      margin: 0px;
      padding: 0px;
      position: absolute;
      right: 0;
    }
  }
}

.dragDrop {
  padding: 20px 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
}

// .mat-menu-item {
//   height: 32px !important;
//   line-height: 32px !important;
// }


// OBJECTS - INLINE-SVG-ICON
.o-inline-svg-icon {
  stroke: currentColor;

  &--baseline {
    position: relative;
    top: .125em;
    width: 1em;
    height: 1em;
  }
}
// COMPONENTS - NAVIGATION-BREADCRUMBS
.c-navigation-breadcrumbs {

  ol {
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;

    & li {
      & a {
        font-weight: 600;
        text-transform: capitalize;
      }

      &:last-child {
        a, a:hover {
          text-decoration: none;
          cursor: default;
        }
      }
    }
  }

  &__directory {
    display: flex;
  }

  &__link:link {
    color: $color-link;

    &:hover {
      @include text-underline();
    }
  }
  // Shorten the breadcrumb trail to include only the next higher level. (Prevent wrap to multiple lines on small screen sizes)
  @media (max-width: 500px) {
    &__item {
      &:not(:nth-last-child(2)) {
        display: none;
      }
    }

    &__link {
      &:before {
        display: inline-block;
        // Invisible space character (sets auto-height for element)
        content: '\200b';
        // User goes "up one level" instead of "navigating back" to the last view or page.
        background: url(icon-svg-uri('arrow-left', $color-link)) center / $size-icon $size-icon no-repeat;
        width: $size-icon;
      }
    }
  }

  @media (min-width: 501px) {
    &__item {
      &:nth-last-child(n+2):after {
        display: inline-block;
        content: '\200b';
        // "Greater-than sign" indicates higher-level page.
        background: url(icon-svg-uri('chevron-right', $color-separator)) center / $size-icon $size-icon no-repeat;
        width: $size-icon;
        margin: 0 $space-separator;
      }
    }

    &__link {
      display: block;
      float: left;
    }
  }
}
// UTILITIES - VISUALLY-HIDDEN
.u-visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.negativeValue {
  color: red;
  font-weight: 500;
}

.actions-btn {
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover, &:focus {
    background-color: rgba(0, 0, 0, .1);
  }
}

.cust-search-field {
  position: relative;
  top: 1px;
  margin: 0px;
  width: 180px;

  & input {
    width: 100%;
    height: 30px;
    padding-right: 38px;
    padding-left: 0px;
    border: none;
    border-bottom: 1px solid $gray-v2-color;
    background-color: transparent;
    outline: none;
  }

  & .search-btn {
    position: absolute;
    right: 0;
    margin: 0px;

    & .search-icon {
      font-size: 24px;
      color: $gray-color;
    }
  }
}

.footer-action-items {
  margin-top: 24px;
  margin-bottom: 24px;

  & button {
    margin-right: 16px;

    &:last-child {
      margin: 0px;
    }
  }
}

.signature-container {
  & .tiny-mc-container {
    & .name-field {
      display: inline-block;
      position: relative;
      padding-right: 28px;

      &:hover {
        & .edit {
          display: inline-block !important;
          padding-right: 0;
        }
      }
    }

    & .edit-label {
      margin-right: 5px;
    }

    & .editable-item {
      border-bottom: 1px solid #9a9a9a;
      border-top: none;
      border-right: none;
      border-left: none;
    }
  }
}

.tiny-mc-container {
  // margin-bottom: 16px;
  // width: 100%;

  // p {
  //   padding-top: 0px;
  //   word-break: break-all;
  // }

  &.header-tiny-mc, &.footer-tiny-mc {
    margin: 0px;

    p {
      margin-bottom: 0px;
    }
  }
}

.sortin-col-right {
  padding-right: 27px !important;
}

// app-daily-loads {
//   & .daily-loads-container {
//     padding-right: 0;
//     padding-left: 0px;
//     /*&-body {
//       max-height: calc(100vh - 182px);
//       height: calc(100vh - 182px);
//     }*/
//   }
// }

.mat-table > tbody tr.mat-row.latest-row td {
  border-color: #A3CAF0;
  border-top: 1px solid #A3CAF0;
}

.client-grid-reassign-dropdown {
  max-width: 230px;
  display: flex;
  align-items: flex-start;
  flex-flow: column;

  .mat-radio-group.filteredItems {
    max-height: 150px;
    padding: 0;
    margin-bottom: 1.28em !important;
  }

  .mat-radio-group.filteredItems .mat-radio-button {
    width: 100%;
  }

  .mat-radio-group.filteredItems .mat-radio-button .mat-radio-label {
    width: 100%;
  }

  .mat-radio-label-content {
    max-width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.editor-container {
  display: flex;
  width: 100%;
  flex-flow: column;
}

.icon-btn {

  &.mat-button {
    min-width: 0;
    padding: 0px;
    height: 24px;
    line-height: 1;
  }
}

.stroked-btn {
  border: 1px solid $primary-secondary-color !important;
  border-radius: 3px !important;
  width: 30px;
  height: 30px;
  padding: 4px !important;

  & .material-icons {
    font-size: 20px;
  }
}

.mat-menu-panel.map-number-container,
.mat-mdc-menu-panel.map-number-container {
  width: 550px;
  max-width: 550px;
  /*height: 600px;*/
  &.mat-menu-before.mat-menu-below {

    & .map-num-dropdown-body {
      @media (max-width:1366px) {
        max-height: 250px;
      }
    }
  }


  & .map-num-dropdown-body {
    max-height: 400px;

    & tr.mat-header-row, & tr.mat-footer-row, & tr.mat-row {
      height: 36px;
    }
  }
}


.map-number-container.leadsheet-container {
  width: 330px;
  max-width: 330px;
  max-height: 400px;
  overflow: hidden;

  & .map-num-dropdown-header {
    border-bottom: 1px solid $gray-v3-color;
  }

  & .map-num-dropdown-body {
    padding: 12px 12px 0 12px;

    ul {
      display: block;
      list-style: none;
      margin: 0px -12px 0px -12px;
      padding: 0px;
      max-height: 300px;
      overflow-y: auto;

      & li {
        display: block;
        list-style: none;
        margin: 0px;
        padding: 0px;

        button {
          height: 36px;

          &.mat-button {
            width: 100%;
            max-width: 100%;
            border-radius: 0px;
            font-size: 13px;
            color: $secondary-color;
            text-align: left;
          }
        }
      }
    }
  }
}

.select-option {
  height: 300px !important;
  overflow-x: auto !important;
  width: 100% !important;
  min-width: 280px !important;

  & .mat-option-text {
    width: 280px;
    max-width: 280px;
    height: 276px;
    overflow-x: auto;
  }
}

ul.cust-items {
  margin: 0px;
  padding: 0px;
  display: block;
  list-style: none;

  li {
    display: block;
    margin: 0px;
    padding: 0px;
  }
}

.balance-sheet-container-aside,
.income-state-container-aside {
  & .report-sheet {
    padding: 24px;
    border: none;
    margin-top: 0px;
  }

  & .report-sheet-actions {
    padding-right: 16px;
  }
}
/*when we click on search icon input filed will be expanded css*/
.search-container {
  position: absolute;
  right: 16px;
  left: 16px;
  top: 1px;
  display: block;
  width: 100%;
  max-width: calc(100% - 32px);

  & .search {
    width: 100%;
    max-width: 300px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    z-index: 9999;

    &.searchTerm {
      display: none;
    }

    &.searchbox-enabled .searchTerm {
      display: flex;
      width: 100%;
    }
  }

  & .searchTerm {
    width: 0;
    border: 1px solid $gray-v2-color;
    padding: 5px 40px 5px 16px;
    height: 38px;
    outline: none;
    color: $secondary-color;
    position: absolute;
    z-index: 1;
    right: 0;
    transition: width 0.5s;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;

    &:focus {
      color: $secondary-color;
    }
  }

  & .searchButton {
    width: 38px;
    min-width: 38px;
    max-width: 38px;
    height: 38px;
    padding: 0;
    border: transparent;
    background: transparent;
    text-align: center;
    color: #D9D9D9;
    border-radius: 50%;
    cursor: pointer;
    font-size: 20px;
    position: relative;
    z-index: 2;
    top: 3px;
  }
}
// Custom slide togle color
// .custom-toggle {

//   & .mat-slide-toggle {
//     margin: 0px;
//   }

//   & .mat-slide-toggle-bar {
//     background-color: rgba(0,0,0,.38);
//   }

//   & .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
//     background-color: $hover-color;
//   }

//   & .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
//     background-color: $primary-secondary-color;
//   }

//   & .mat-slide-toggle.mat-checked .mat-ripple-element {
//     background-color: $primary-secondary-color;
//   }
// }
.folder-panel {

  & .folders {
    width: 275px;
    max-height: 250px;
    padding: 12px;
    padding-right: 0px;
    padding-left: 8px;
    overflow: auto;
    overflow-x: hidden;

    &-header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      height: 20px;
      margin-bottom: 8px;
      position: relative;

      & .close-btn {
        position: absolute;
        right: 3px;
        top: -3px;
      }

      & .material-icons {
        font-size: 20px;
      }
    }

    & .clearall {
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      color: $hyper-link-color;
      padding-right: 4px;

      & .clear {
        cursor: pointer;
      }
    }

    & .folder-details-list {
      padding: 0px 4px;
      height: 26px;

      & .float-left {
        & .name {
          & .foldername {
            max-width: 160px;
          }
        }
      }

      &-else {
        padding: 12px;
        display: flex;
        justify-content: center;
        border: 1px dashed $gray-v2-color;
        border-radius: 5px;
        margin: 8px;
      }
    }
  }
}


.teams-list-container {
  position: relative;

  & .teams-list-box {
    position: absolute;
    z-index: 999;
    top: -18px;
    right: -5px;
    width: 250px;
    height: 200px;
    background-color: $white-color;
    overflow: auto;
    padding-left: 4px;
    padding-right: 4px;
    border-bottom: 1px solid $gray-v2-color;
    box-shadow: 2px 2px 2px 2px #aaaaaa;
    border-radius: 1px;

    &-else {
      padding: 12px;
      display: flex;
      justify-content: center;
      border: 1px dashed $gray-v2-color;
      border-radius: 5px;
    }

    & .body {
      height: 75px;
      width: 225px;
      border-bottom: 1px solid $gray-v11-color;
      padding: 8px 0px;

      & .left-section {
        display: flex;
        align-items: center;


        & .short-name {
          height: 24px;
          min-width: 24px;
          min-height: 24px;
          border-radius: 50%;
          font-size: 12px;
          color: $white-color;
          text-transform: uppercase;
          line-height: 1;
          justify-content: center;
          margin-right: 8px;
          border: 1px solid;
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
        }

        & .person-details {
          max-width: 120px;

          & .name {
            display: flex;
            align-items: flex-end;

            & .hyperlink {
              text-decoration: underline;
              color: $hyper-link-color;
              max-width: 105px;
            }

            & .wrapper {
              & .btn {
                padding: 0px !important;
              }
            }
          }

          & .team-role {
            font-style: italic;
          }

          & .phone-number {
            font-size: 12px;
          }
        }
      }

      & .right-section-popup {

        & .edit-access-popup {
          display: flex;
          align-items: flex-end;

          & .add-btn {
            height: 24px !important;
          }

          & .hyperlink {
            text-decoration: underline;
            color: $hyper-link-color;
            font-size: 12px;
          }

          & .wrapper {
            & .btn {
              padding: 0px !important;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.confirmation-dialog {
  position: relative;
  width: 352px;
  min-width: 352px;
  max-width: 352px;
  height: 152px;
  max-height: 152px;

  &-actions {
    width: 100%;
    padding: 0px 24px;
  }

  & .close-dialog {
    position: absolute;
    right: 0px;
    top: 0px;
  }

  & .no-btn {
    border: 1px solid $gray-v2-color;
  }

  & .no-btn, & .yes-btn {
    width: 130px !important;
    height: 36px !important;
  }

  & .mat-dialog-content {
    margin-bottom: 20px;
  }

  & .mat-dialog-title {
    font-size: 19px;
    display: flex;

    & .warn-ico {
      margin-right: 6px;
    }
  }

  & .dialogbox {
    text-align: center;
    font-size: 14px;
    color: black;
  }
}

.create-folder-dialogbox {
  &-header {
    border-bottom: 1px solid $gray-v11-color;
    margin-bottom: 12px !important;

    & .create-folder {
      display: block;
      margin: 0px auto;
    }
  }


  & .input-field {
    width: 100%;
  }
}

.filter-sort {
  //margin-top: 20px;
  .mat-menu-content {
    width: 180px;

    .mat-menu-item {
      // display: flex;
      // flex-flow: row nowrap;
      // justify-content: space-between;

      & .done-icn {
        font-size: 19px;
        margin-top: 5px;
        color: #00874E;
      }
    }
  }
}

a.file-hover {
  color: $black-color !important;
  font-weight: 600;
  cursor: pointer;
}

.doc-file {
  a {
    color: $black-color !important;
  }
}

.available-container-body-content-expansioncard.cdk-drag.cdk-drag-dragging .available-container-body-content-expansion-card.cdk-drag.cdk-drag-preview {
  width: 285px !important;
  border: 1px solid #E0E0E0;
  background-color: #fff;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.available-container-body-content-expansion-card.cdk-drag.cdk-drag-preview .available-container-body-content-expansion-card-right {
  display: none;
  align-items: center;
  flex-flow: row nowrap;
  width: 44px;
}

.available-container-body-content-expansion-card.cdk-drag.cdk-drag-preview .available-container-body-content-expansion-card-right .done-all-button {
  width: 22px;
  height: 22px;
  line-height: 22px;
}

.available-container-body-content-expansion-card.cdk-drag.cdk-drag-preview .flex-box-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 229px;
}

.available-container-body-content-expansion-card.cdk-drag.cdk-drag-preview .flex-box-left .available-container-body-content-expansion-card-left {
  display: flex;
  align-items: center;
}

.doc-hub-available-card.cdk-drag.cdk-drag-preview .flex-box-left .available-container-body-content-expansion-card-left {
  display: flex;
  align-self: flex-start !important;
  // padding-top: 3px !important;
  & .drag-button {
    & .drag-icon {
      padding-top: 2px;
    }
  }
}

.available-container-body-content-expansion-card.cdk-drag.cdk-drag-preview .flex-box-left .available-container-body-content-expansion-card-left img {
  width: 18px;
}

.available-container-body-content-expansion-card.cdk-drag.cdk-drag-preview .flex-box-left .available-container-body-content-expansion-card-left .drag-button .drag-icon {
  color: #9e9e9e;
  font-size: 20px;
}

.available-container-body-content-expansion-card.cdk-drag.cdk-drag-preview .flex-box-left .available-container-body-content-expansion-card-middle {
  padding-left: 8px;
}

.available-container-body-content-expansion-card.cdk-drag.cdk-drag-preview .flex-box-left .available-container-body-content-expansion-card-middle .doc-type p {
  font-weight: 500;
}

.available-container-body-content-expansion-card.cdk-drag.cdk-drag-preview .flex-box-left .available-container-body-content-expansion-card-middle .doc-type {
  display: flex;
  align-items: center;
}

.available-container-body-content-expansion-card.cdk-drag.cdk-drag-preview .flex-box-left .available-container-body-content-expansion-card-middle .doc-type .document {
  font-size: 14px;
  color: #212121;
  font-weight: 600;
  width: auto;
  max-width: 154px;
}

.available-container-body-content-expansion-card.cdk-drag.cdk-drag-preview .flex-box-left .available-container-body-content-expansion-card-middle .name {
  font-size: 11px;
  color: #00508E;
}

.available-container-body-content-expansion-card.cdk-drag.cdk-drag-preview .flex-box-left .available-container-body-content-expansion-card-middle p.date-time {
  font-size: 11px;
  color: #9A9A9A;
}

.available-container-body-content-expansion-card.cdk-drag.cdk-drag-placeholder {
  opacity: 0;
  background-color: $ntr-menu-active;
  min-width: 264px;
}

.ntr-panel-left .mat-accordion .mat-expansion-panel-body
ul li .mat-expansion-panel-content .mat-expansion-panel-body ul li
 .signOff-panel-wrapper.cdk-drop-list-dragging:active {
  background-color: #DDEDF8 !important;
}
.signOff-panel-wrapper.child-level-signoff .signOff-left:active
{
  background-color:#DDEDF8 !important;
}
.ntr-panel-left .mat-accordion .mat-expansion-panel-body ul li
.mat-expansion-panel-content .mat-expansion-panel-body ul li
.signOff-panel-wrapper.cdk-drag.cdk-drag-placeholder {
  background-color: $ntr-menu-active;
}

.signOff-left.cdk-drag.cdk-drag-dragging {
  display: none;
}

.signOff-left.cdk-drag.cdk-drag-placeholder {
  background-color: $ntr-menu-active !important;
}
/* .cdk-drop-list-dragging .signOff-left:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
} */
/*.leadsheet-label-child1*/
.signOff-left.cdk-drag.cdk-drag-preview {
  width: 285px !important;
  border: 1px solid #E0E0E0;
  background-color: #fff;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.signOff-left.cdk-drag.cdk-drag-preview .leadsheet-label-child1 {
  border: none;
}
// .cdk-drag-animating {
//   transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
// }
.cdk-drop-list-dragging .available-container-body-content-expansion-card:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.document-hub {
  & .cdk-drag-placeholder {
    opacity: 0;
  }

  .available-container-body-content-expansion .mat-expansion-panel-header-title.cdk-drop-list-dragging {
    background-color: $ntr-menu-active;
    display: flex;
    align-items: center;
  }
  //.available-container-body-content-expansion-card.cdk-drag.cdk-drag-placeholder
  .available-container-body-content-expansion .mat-expansion-panel-header-title.cdk-drop-list-dragging .available-container-body-content-expansion-card.cdk-drag.cdk-drag-placeholder {
    opacity: 0;
    height: 38px;
  }

  .available-container-header.cdk-drop-list.cdk-drop-list-dragging {
    background-color: $ntr-menu-active;
  }

  .available-container-header.cdk-drop-list.cdk-drop-list-dragging .available-container-body-content-expansion-card.cdk-drag.cdk-drag-placeholder {
    display: none;
  }

  .available-container-body-content-expansion .mat-expansion-panel-body .available-container-body-content-expansion-card.cdk-drag.cdk-drag-placeholder {
    opacity: 1;
  }
}


.balance-sheet-list-container {
  & .balance-sheet-list-item {
    &.cdk-drag.cdk-drag-preview {
      background-color: $ntr-menu-active;
      border: 1px solid $primary-secondary-color;
      cursor: grabbing;
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

      & .col-status {
        display: none;
      }
    }
  }
}

.balance-sheet-list-item.cdk-drag.cdk-drag-preview {
  background-color: $ntr-menu-active;
  border: 1px solid $primary-secondary-color;
  cursor: grabbing;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 28px;
  overflow: hidden;
  border-radius: 0px;

  & .col-notesfs {
    min-width: 98px;
    max-width: 98px;
    padding-right: 8px;
    text-align: center;
    height: 28px;
    padding-top: 5px;
  }

  & .col-originalpanel {
    min-width: 145px;
    max-width: 145px;
    padding-right: 8px;
    margin-right: 8px;
    height: 28px;
  }

  & .col-descpanel {
    min-width: 90px;
    max-width: 90px;
    padding-right: 8px;
    margin-right: 8px;
  }

  & .list-sec-block {
    & .drag-drop {
      font-size: 18px;
      color: #9e9e9e;
      padding-top: 2px;
    }

    & .mat-checkbox {
      margin-right: 8px;
    }
  }

  & .list-icons {
    & .ico-lock {
      color: $action-icons-color;
      font-size: 20px;
    }
  }
}

.sections-drop.cdk-drag.cdk-drag-preview {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.balance-sheet-list-item.cdk-drag.cdk-drag-preview .col-status, .balance-sheet-list-item.cdk-drag.cdk-drag-preview .arr-drop {
  display: none;
}

.balance-sheet-list-item.cdk-drag.cdk-drag-preview .custom-checkbox {
  margin: 3px 10px 0 0;
}

.balance-sheet-list-item.cdk-drag.cdk-drag-preview .map-num-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.balance-sheet-list-item.cdk-drag.cdk-drag-preview .ls-btn {
  margin-top: 2px;
}

.balance-sheet-list-item.cdk-drag.cdk-drag-preview .drag-drop-left {
  margin-right: 5px;

  &-icon {
    display: block;
    position: relative;
    top: 1px;
    right: 5px;
  }
}

.example-custom-placeholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
/*Add Reference dialog box css*/
.reference-container {
  width: 752px;
  min-width: 752px;
  max-width: 752px;

  &-header {
    padding-bottom: 17px;

    & .ref-title {
      font-size: 20px;
      color: $color-link;
      text-transform: capitalize;
    }
  }

  &-body {
    &-title {
      margin-bottom: 20px;

      & .question-title {
        font-size: 12px;
        color: $color-link;
        background-color: #EDEDED;
        text-transform: capitalize;
        padding: 10px;
        font-weight: 600;
      }
    }

    &-section {
      margin-bottom: 20px;

      &-left, &-right {
        & .select-field {
          text-transform: capitalize;
        }

        &-reference {
          width: 330px;
          height: 242px;
          background-color: $white-v6-color;
          border: 1px solid #BDBDBD;
          padding: 20px;
          overflow: auto;

          & .list-style {
            list-style-type: none;
          }

          & .document-folder {
            background-color: #fff;
            border: 1px solid #E0E0E0;
            width: 100%;
            padding: 8px 0px 7px 15px;
            margin: 0px 48px 0px 14px;
            box-shadow: 0px 1px 3px #0000000F;
            border-radius: 5px;

            & .pdf-title {
              font-size: 14px;
              color: $color-link;
              padding-left: 15px;
              padding-right: 8px;
              min-width: 210px;
              max-width: 210px;
            }
          }
        }
      }

      &-middle {
        & .arrow-btn {
          width: 38px;
          min-width: 38px;
          max-width: 38px;
          height: 38px;
          padding: 0px;

          & .arrow-icn {
            font-size: 38px;
          }
        }
      }

      &-right {
        &-title {
          padding-bottom: 11px;
          margin-top: 22px;

          & .ref-title {
            font-size: 14px;
            color: $color-link;
            text-transform: capitalize;
            font-weight: 600;
          }
        }

        &-reference {
          & .delete-button {
            width: 24px;
            height: 24px;
            line-height: 24px;

            & .delete-icon {
              color: #A6A6A6;
              width: 21px;
              height: 21px;
              font-size: 21px;
            }
          }

          & .mar-r-28 {
            margin-right: 28px;
          }
        }
      }
    }
  }
}
/*Upload Client Documents Dialog Box CSS*/
.upload-client-container {
  height: 100%;
  max-height: 530px;
  margin-bottom: -24px;

  .mat-dialog-content.mat-dialog-content {
    margin: 0px -48px;
  }

  &-header {
    padding: 0px 0px 16px 0px;
    margin: 0px -13px 0px -4px;

    & .upload-title {
      font-size: 20px;
      color: $color-link;
      font-weight: 600;
      text-transform: capitalize;
    }

    &-icon {
      & .close-btn {
        width: 22px;
        height: 22px;
        line-height: 22px;

        & .close-icn {
          font-size: 22px;
          color: $gray-v4-color;
        }
      }
    }
  }

  &-body {
    border-top: 1px solid #EFEFEF;
    height: 100%;
    max-height: 417px;

    &-left {
      height: 100%;
      width: 220px;

      & .list-items {
        list-style: none;

        &-title {
          font-size: 14px;
          color: $color-link;
          font-weight: 500;
          text-transform: capitalize;
          border-bottom: 1px solid $gray-v11-color;
          height: 42px;
          padding-left: 20px;
          padding-right: 10px;
          position: relative;

          & .custom-progress-bar {

            & .progress-status-value {
              display: block;
              text-align: left;
            }

            & .mat-progress-bar {
              width: 80px !important;
            }

            & .mat-progress-bar-fill {

              &:after {
                background-color: $primary-color !important;
              }
            }
          }

          & .title {
            font-size: 14px;
            color: $color-link;
            width: auto;
            max-width: 80px;
          }

          &.active-link {
            &:before {
              position: absolute;
              content: '';
              width: 5px;
              height: 42px;
              left: 0px;
              top: 0px;
              background-color: $primary-color;
            }
          }
        }

        & .example-margin {
          margin: 0 10px;
          position: relative;
          width: 36px !important;
          height: 36px !important;

          & svg {
            width: 36px !important;
            height: 36px !important;
          }
        }

        & .spinner {
          position: absolute;
          z-index: 1;
          top: 186px;
          left: 384px;
          font-size: 12px;
        }
      }
    }

    &-middle {
      width: 382px;
      height: 100%;
      border-right: 1px solid $gray-v11-color;
      border-left: 1px solid $gray-v11-color;

      &-header {
        &-icons {
          padding-left: 12px;
          padding-top: 10px;

          & .list-icon {
            width: 30px;
            height: 30px;
            line-height: 30px;
            margin-right: 19px;

            &:last-child {
              margin-right: 0px;
            }
          }

          & .active {
            background-color: #EDEDED;
            border-radius: 0px;
          }
        }
      }

      &-body {
        padding-left: 22px;
        margin-top: 18px;
        height: calc(100vh - 299px);
        max-height: calc(100vh - 299px);
        overflow-y: auto;

        &-folders {
          height: calc(100vh - 299px);

          & .list-items {
            list-style: none;
            display: flex;
            flex-flow: row wrap;

            &-title {
              font-size: 10px;
              color: $color-link;
              text-transform: capitalize;
              border: 1px solid $gray-v14-color;
              margin-right: 15px;
              margin-bottom: 12px;
              width: 64px;
              height: 64px;
              padding-top: 10px;

              &:last-child {
                margin-right: 0px;
              }

              & .folder-approved-icn {
                width: 24px;
                height: 24px;
                line-height: 24px;
              }
            }

            & .folder-icon {
              color: $gray-v13-color;
            }

            & .folder {
              position: relative;
              /*  &-image {
                } */
              &-percentage {
                position: absolute;
                z-index: 1;
                right: 0px;
                top: 6px;
                left: 5px;

                & .title-percent {
                  font-weight: 600;
                }
              }
            }
          }

          & .folder {
            &-header {
              border-bottom: 1px solid #E4E4E4;
              padding-left: 16px;
              padding-bottom: 8px;

              &-left {
                width: 194px;
                padding-right: 16px;
              }

              &-middle {
                width: 100px;
                padding-right: 16px;
              }

              &-right {
                width: 100px;
              }

              & .header-title {
                font-size: 12px;
                color: $color-link;
                font-weight: 600;
                text-transform: capitalize;
              }
            }

            &-body {
              height: calc(100vh - 325px);
              overflow-y: auto;

              & .list {
                list-style: none;
              }

              & .list-of-items {
                border-bottom: 1px solid $gray-v11-color;
                padding: 10px 10px 10px 17px;
              }

              & .header-title {
                font-size: 12px;
                color: $color-link;
                font-weight: 600;
                text-transform: capitalize;
              }

              & .col-owner {
                width: auto;
                max-width: 64px;
              }

              &-details {
                &-name {
                  width: 166px;
                  margin-right: 13px;

                  & .custom-progress-bar {

                    & .progress-status-value {
                      display: block;
                      text-align: left;
                    }

                    & .mat-progress-bar {
                      width: 40px !important;
                    }

                    & .mat-progress-bar-fill {

                      &:after {
                        background-color: $primary-color !important;
                      }
                    }
                  }
                }

                &-left {
                  width: 172px;
                  margin-right: 22px;
                }

                &-middle {
                  width: 100px;
                  padding-right: 10px;
                }

                &-right {
                  width: 90px;
                }

                & .content {
                  font-size: 10px;
                  color: #AAAAAA;

                  &-owner, &-date {
                    font-size: 12px;
                    color: #AAAAAA;
                  }
                }

                & .folder-img {
                  margin-right: 12px;
                }
              }
            }
          }


          & .mar-t-4 {
            margin-top: 4px;
          }

          & .mar-r-12 {
            margin-right: 12px;
          }

          & .mar-b-20 {
            margin-bottom: 20px;
          }
        }
      }
    }

    &-right {
      width: 350px;
      height: 100%;
      background-color: $gray-v14-color;
      border: 1px dashed $gray-v15-color;
      margin: 7px 17px 7px 7px;

      &-drag-drop {
        padding: 168px 0px;

        & .upload-icon {
          font-size: 40px;
          color: $gray-v15-color;
        }

        & .drag-drop {
          font-size: 12px;
          color: #555555;
        }

        & .file-wrapper {
          & .button {
            font-size: 12px;
          }
        }
      }
    }

    & .percent-content {
      font-size: 12px;
      color: $gray-v4-color;
    }
  }

  & .dialog-actions {
    padding-right: 15px;
    background-color: $white-v6-color;
    border: 1px solid $gray-v11-color;
    margin: 0px -24px 0px -24px;

    & .done-btn {
      width: 100%;
    }
  }
}

.click-disabled {
  pointer-events: none !important;
  opacity: .5 !important;
}

.mat-menu-panel.dl-status-box,
.mat-mdc-menu-panel.dl-status-box {
  max-width: 100% !important;
}

.sticky-item {
  position: sticky !important;
  top: 0;
  background-color: #fff;
  z-index: 1;

  &.top-pos-33 {
    top: 33px !important;
  }
}
//
.available-file.cdk-drop-list-dragging {
  background-color: transparent;
}
//CSS For typo head search drop down
.map-head-dropdown {
  &.ng-select-opened {
    & .ng-select-container {
      & .ng-value-container {
        & .ng-placeholder {
          transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px) !important;
          transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
        }
      }
    }
  }

  &.ng-select-focused {
    & .ng-select-container {
      &::after {
        border-bottom: 1px solid $primary-color !important;
      }
    }

    & .ng-arrow-wrapper {
      & .ng-arrow {
        color: $primary-color !important;
      }
    }
  }

  & .ng-select-container {
    min-height: 43px !important;
    max-height: 43px !important;



    & .ng-value-container {
      // padding-top: 10px !important;
      & .ng-placeholder {
        //color: $black-color !important;
        //font-weight: bold !important;
        //font-size: 1px;
        transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
        //transform: translateY(2px) scale(1) perspective(100px) translateZ(0.00106px) !important;
      }
    }
  }
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  position: absolute !important;
  color: #A19E9E !important;
  transform-origin: 0 0 !important;
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
}
.ng-select.ng-select-disabled .ng-select-container:after{
	border-bottom-color: rgba(0, 0, 0, 0.2) !important;
    background-image: none !important;
}
.ng-select.ng-select-disabled .ng-select-container .ng-arrow-wrapper {
    opacity: 0.5 !important;
}

.disabled-browse-link {
  opacity: 0.5;
  pointer-events: none !important;
  text-decoration: underline;
  color: #030906 !important;
}

// .disable-button {
//   opacity: 0.5;
//   background-color: $primary-secondary-color !important;
//   color: #030906 !important;
// }
.disable-button2 {
  opacity: 0.5;
  color: #030906 !important;
}

.radio-size-14 {
  & .mat-radio-button {
    & .mat-radio-container {
      width: 14px;
      height: 14px;

      & .mat-radio-inner-circle, & .mat-radio-outer-circle {
        width: 14px;
        height: 14px;
      }

      & .mat-radio-ripple.mat-ripple {
        width: 28px;
        height: 28px;
        left: calc(50% - 14px);
        top: calc(50% - 14px);
      }
    }
  }
}

.fs-menu-radio {
  display: flex;
  width: 120px;
  flex-flow: row nowrap;
  justify-content: space-between;

  & .radio-btn {
    line-height: 1;

    & .mat-radio-label {
      margin-bottom: 0px !important;
    }

    & .mat-radio-label-content {
      padding-left: 8px;
    }
  }
}

.disabled {
  & .mat-select-disabled .mat-select-value {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
  //transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px) !important;
  color: #A19E9E !important;
  font-size: 12px !important;
  transform: translateY(-17px) scale(1) perspective(100px) translateZ(0.00106px) !important;
}

.ng-select.ng-select-focused .ng-select-container:after {
  border-color: $primary-secondary-color !important;
}

.ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
  color: $primary-secondary-color !important;
}

.ng-select .ng-has-value .ng-placeholder, .ng-select.ng-select-opened .ng-placeholder {
  transform: translateY(-1.28125em) scale(1) perspective(100px) translateZ(0.001px) !important;
}

.required-asterisk {
  color: #f44336;
  font-weight: bold;
  margin-left: 3px;
  font-size: 13px;
}
/* Border Color */
.ng-select.ng-invalid.ng-touched .ng-select-container:after {
  border-color: #f44336;
}
/* Arrow Color */
.ng-select.ng-invalid.ng-touched .ng-arrow-wrapper .ng-arrow {
  border-color: #f44336 transparent transparent
}
/* Placeholder Color */
.ng-select.ng-invalid.ng-touched .ng-select-container .ng-placeholder {
  color: #f44336;
}

.team-dropdown .ng-select-container .ng-value-container .ng-placeholder {
  position: absolute !important;
  color: rgba(0, 0, 0, 0.54) !important;
  transform-origin: 0 0 !important;
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
}

.selectng-dropdown {
  & .ng-select-container {
    &::after {
      bottom: 8px;
    }

    & .ng-value-container {
      & .ng-input {
        bottom: 12px;
      }

      & .ng-placeholder {
        bottom: 15px;
      }
    }

    & .ng-arrow-wrapper {
      bottom: 15px !important;
    }
  }
}

.team-dropdown .ng-has-value.ng-select-container .ng-value-container .ng-placeholder {
  position: absolute !important;
  color: #A19E9E !important;
  transform-origin: 0 0 !important;
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
}


.team-dropdown.ng-select-opened .ng-select-container .ng-value-container .ng-placeholder {
  color: #A19E9E !important;
  //font-weight: 600 !important;
  font-size: 14px !important;
  transform: translateY(-17px) scale(1) perspective(100px) translateZ(0.00106px) !important;
}



.team-dropdown.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
  //transform: translateY(-1.28125em) scale(1) perspective(100px) translateZ(0.001px) !important;
  color: #A19E9E !important;
  //font-weight: 600 !important;
  font-size: 14px !important;
  transform: translateY(-17px) scale(1) perspective(100px) translateZ(0.00106px) !important;
}



.team-dropdown .ng-has-value .ng-value-container .ng-placeholder {
  //transform: translateY(-1.28125em) scale(1) perspective(100px) translateZ(0.001px) !important;
  color: #A19E9E !important;
  //font-weight: 600 !important;
  font-size: 14px !important;
  transform: translateY(-17px) scale(1) perspective(100px) translateZ(0.00106px) !important;
}

// .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
//   background: $gray-v20-color;
//   color: $black-color;
// }

// .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
//   background: $hover-color;
// }

// .mat-option:hover:not(.mat-option-disabled) {
//   background: $hover-color;
// }

#jsd-widget {
  right: auto !important;
  left: 0;
}

.mat-form-field-hint-wrapper {
  font-size: 12px !important;
}

.ng-select-opened .ng-select-container .ng-value-container .ng-placeholder {
  color: #A19E9E !important;
  font-size: 14px !important;
  transform: translateY(-17px) scale(1) perspective(100px) translateZ(0.00106px) !important;
}

.strike-text {
  color: #A7A7A7 !important;
  text-decoration: line-through;
}

.mar-r-10 {
  margin-right: 10px !important;
}

.myaccount-settings-menu {
  & .mat-expansion-panel {
    & .mat-expansion-panel-content {
      & .mat-expansion-panel-body {
        padding: 0 24px 0px 40px;
      }
    }

    &.mat-expanded {
      & .mat-expansion-panel-content {
        & .mat-expansion-panel-body {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.firm-settings-section-content.user-change-password .firm-settings-section-content-body .firm-profile-header {
  border-bottom: none;
}

.disable-text-button {
  opacity: 0.4 !important;
}

.col-disabled {
  opacity: 0.4 !important;
  cursor: default !important;
  pointer-events: none !important;
}

.report-sheet.show-draft {
  position: relative;
  z-index: 1 !important;

  &::before {
    content: "";
    position: absolute;
    top: 30px;
    left: -60px;
    bottom: 0;
    right: 0;
    background: url('./assets/images/PDF-watermark.png') no-repeat 0 0 !important;
    background-position: bottom center;
    background-size: 100%;
    z-index: -1;
    transform: rotate(-6deg);
  }
}
/* @media print {

  .ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
  }
  .has-child .subheading{
    float: left;
    width: 500px;
    max-width: 500px;
  }

} */

@page {
  size: A4;
}

@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }

  li.has-child.null-child {
    padding: 5px 0;
  }
}

.peg-subs {
  top: 22px;
  width: 100%;
  position: relative;
  left: 16px;
  min-width: 0 !important;
}

.captalized{
  text-transform: capitalize;
}

.t-new-line{
  white-space: pre-line;
}




.doc-menu-details {
  /*max-height:100px;
  overflow:auto;*/
&-list {
  width: 100%;
  height: 32px;
  padding: 12px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: $hover-color;

    & .list-right {
      & button.delete-btn:enabled {
        color: #00508E;
      }
    }
  }


  & .list-left {
    margin-right: 12px;
    max-width: 195px;
    min-width: 195px;

    .hand-cursor {
      cursor: pointer;
    }
  }

  & .list-right {
    & .delete-btn {
      color: $action-icons-color;
      margin-top: 6px;
    }
  }
}
}
.mat-menu-panel.doc-menu.mat-menu-before.mat-menu-below,
.mat-mdc-menu-panel.doc-menu.mat-menu-before.mat-menu-below {
margin-top: 6px;
position: relative;
overflow: visible;
min-width: 270px !important;
max-width: 270px !important;
max-height:150px !important;
&:before {
  position: absolute;
  top: -8px;
  right: 5px;
  content: '';
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #FFFFFF;
}
}
.mat-menu-panel.doc-menu.mat-menu-before.mat-menu-above,
.mat-mdc-menu-panel.doc-menu.mat-menu-before.mat-menu-above {
margin-bottom: 6px;
position: relative;
overflow: visible;
min-width: 270px !important;
max-width: 270px !important;
max-height: 150px !important;
& .mat-menu-content{
    overflow:auto;
    max-height:130px;
}
&:before {
  position: absolute;
  bottom: -7px;
  right: 20px;
  content: '';
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #FFFFFF;
}
}
.mat-menu-panel.doc-menu.mat-menu-after.mat-menu-above,
.mat-mdc-menu-panel.doc-menu.mat-menu-after.mat-menu-above {
margin-bottom: 6px;
position: relative;
overflow: visible;
min-width: 270px !important;
max-width: 270px !important;
max-height: 150px !important;

& .mat-menu-content {
  overflow: auto;
  max-height: 130px;
}

&:before {
  position: absolute;
  bottom: -7px;
  left: 20px;
  content: '';
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #FFFFFF;
}
}
.doc-menu {
  //  &:focus{
  // // padding: 16px 0px 16px 16px;
  // // border: 1px solid #0F60A6;
  // // border-radius: 3px;
  // // box-sizing: border-box;
  // // margin: 0px 16px 0px 12px;
  // //height: 63px;
  // text-decoration: underline;
  //  }
  & label, & textarea {
    display: block;
  }

  & label {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 600;
  }

  & textarea {
    width: 100%;
    border: none;
  }
}



.ref-dropdown-container {
  width: 300px;
  //height: auto;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #E0E0E0;
  opacity: 1;
  max-height: 250px !important;
  overflow: hidden !important;
  min-height: 120px !important;

  &-body {
    display: flex;
    align-items: center;
    padding-left: 4px;
    padding-right: 0px !important;
    height: 34px;
    line-height: 1;

    &:hover {
      background-color: $hover-color;
    }

    & .checkbox {
      margin-right: 8px;
    }
  }

  &-main {
    height: auto;
    min-height: 40px;
    max-height: 185px;
    overflow: auto;
    border-bottom:1px solid $gray-v11-color;
    margin-left: 8px;
    padding-bottom: 8px;
    margin-right: 8px;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    margin-right: 14px;
    // margin-bottom: 6px;
  }
}

.mat-dialog-container {
  display: block;
  padding: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: inherit !important;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}


// .fs-14{
//     font-family: Open Sans !important;
//     font-style: normal;
//     font-weight: bold;
//     font-size: 14px;
// }
.open-sans{
  font-family: Open Sans !important;
}
.maxwidth{
  max-height: 137px;
}
.minwidth{
  min-height: auto;
}
.checklist-scroll-bar {
  overflow: auto;
  height: auto;
  //     overflow: auto;
  // max-height: 140px;
  // min-height: 40px;
    // overflow: auto;
    // max-height: 136px;
    // height: calc(100vh - 822px);
    // min-height: calc(100vh- 822px);
    // overflow: auto;
    // @media (min-width: 1504px) {
    //   height: calc(100vh - 567px);
    // min-height: calc(100vh- 567px);
    // overflow: auto;
    // }
    // @media (min-width: 1880px) {
    //   height: calc(100vh - 729px);
    // min-height: calc(100vh- 729px);
    // overflow: auto;
    // }
    // @media (min-width: 1920px) {
    //   height: calc(100vh - 822px);
    // min-height: calc(100vh- 822px);
    // overflow: auto;
    // }
    // @media (min-width: 1) {
    //   height: calc(100vh - 822px);
    // min-height: calc(100vh- 822px);
    // overflow: auto;
    // }

}


.radio-tick:checked + .radio-tick-label1:before {
  font-family: "Material Icons";
  content: "\e876";
  background: #339C7D;
  color: #fff;
  border: 2px solid #339C7D;
  padding: 10px;
}
.radio-tick + .radio-tick-label1:before {
  border-radius: 50%;
}
.checkbox-tick + .checkbox-tick-label:before, .radio-tick + .radio-tick-label1:before {
  content: '';
  background: #fff;
  border: 2px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 2px;
  text-align: center;
  font-family: "Material Icons";
  content: "\e876";
  color: #ddd;
  padding: 10px;
}

.paginated-view {
  > .page {
    margin: 0 auto;
    margin-bottom: 0.5cm;
  }
}
.page {
  // display: block;
  // background: white;
  // box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  // overflow: hidden;
  // position: relative;
  .page-number {
    position: absolute;
    bottom: 10px;
    right: 30px;
    color: #555;
    font-size: 11px;
    font-family: monospace;
  }
}

// .A4 {
//   width: 595px;
//   height: 842px;
//   padding: 10px 25px;
// }

// .A3 {
//   width: 842px;
//   height: 1191px;
//   padding: 10px 25px;
// }


@font-face {
  font-family: "SeaweedScript";
  src: url(./assets/fonts/SeaweedScript-Regular.ttf) format("truetype");
}

.icon-text {
  margin-top: 7px;
  display: inline-flex;
}
.dropdown-icn{
    font-size: 12px;
    margin-right: 5px;
    position: relative;
    top: 2px;
  }
  .input-f{
    background: none;
    padding: 5px;
    border: 1px solid black;
    border-radius: 4px;
  }

  .unap1-yes-no {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
}


button.mat-stroked-button{
  border: 1px solid $first-secondary-color;
  border-radius: 4px;
}
.team-dashboard-engagement-body-heading .search-field input, .mapno-dropdown-header .search-field input
{
  border: 1px solid #BAB9BD !important;
  border-radius: 4px;
  padding-left: 10px !important;
  height: 36px !important;
  padding-right: 0 !important;
}
.team-dashboard-engagement-body-heading .search-field{
  top: -4px !important;
}
.bulk-map-dailog .body .database .body-database .mapno-bulk-dropdown-header .search-field
{
  top: 4px !important;
}
.mapno-dropdown-header .search-field{
  top: 0 !important;
}
.mapno-dropdown-header .search-field input{
  padding-right: 0 !important;
}
.team-dashboard-engagement-body-heading{
  padding-right: 0 !important;
}
.pac-container {
  background-color: #fff;
  position: absolute!important;
  z-index: 1000;
  border-radius: 2px;
  border-top: 1px solid #BAB9BD;
  font-family: Arial,sans-serif;
  box-shadow: 0 2px 6pxrgba(0,0,0,.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 11px;
}
.mat-checkbox.mat-checkbox-checked .mat-checkbox-background{
  background-color: #339C7D;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color: #339C7D;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle{
  background-color: #339C7D;
}
.create-eng-body .left-panel-eng-bottom-header .material-icons{
  color: #339C7D !important;
}
// .add-team-container .access-permissions-container .mat-accordion .mat-expansion-panel-header .mat-expansion-indicator{
//   filter: grayscale(0%) brightness(60%) sepia(109%) hue-rotate(120deg) saturate(499%);
// }
.document-hub-main-body-newreq{
  & .disable-button{
    background-color: transparent !important;
    border: 1px solid #337ebd;
  }
}
.balance-sheet-container-selection .bals-details .mat-form-field,
.leadsheet-grouping-header-section-left .entity-field,
.income-state-container-header-section-left .entity-field,
.leadsheet-grouping-header-section-left .yearend-dropdown,
.income-state-container-header-section-left .yr-dropdown, .yr-dropdown {
 max-width: 160px !important; width: 160px !important;
}

.pushback-entry{
  padding: 0px 4px 4px 4px;
  margin: 0px 8px 0px 0px;
  width: auto;
  height: 18px;
  border-radius: 4px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  background: #339C7D;
}

.grecaptcha-badge {
  visibility: hidden;
}



.choose-temp-outer {
  .mat-tab-header-pagination {
    display: none !important;
  }
}

