body {
  .border-error{
    border-color: $error !important;
  }
    .dialog-close-btn {
        &:hover {
            color: $error;
        }
    }
    .mat-dialog-container {
        border-radius: $radius-12;
    }
    .mat-dialog-actions {
        margin-bottom: initial;
        padding: 0;
    }


    * {

        &::-webkit-scrollbar {
          width: 12px;
          height: 12px;

          @media (max-width:1440px)  {
            width: 8px;
            height: 8px;
          }
        }

        &::-webkit-scrollbar-track {
          background-color: $gray-1;
          border-radius: $radius-8;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $gray-3;
          border-radius: $radius-8;
        }

      }
}

.shadow-none {
  box-shadow: none !important;
}

.overflow-x-auto {
    overflow-x: auto !important;
}
.overflow-visible {
    overflow: visible !important;
}
.overflow-inherit {
    overflow: inherit !important;
}
.overflow-initial {
  overflow: initial !important;
}


img {
    max-width: 100%;
    height: auto;
    max-height: 100%;
}
mat-datepicker {
    display: none;
}
.seprator {
    position: relative;
    @include flex-combinator(row, nowrap, center, center);
    margin: 1.5rem auto;
    width: 100%;
    &:before,
    &:after {
        height: 1px;
        background-color:  $gray-2;
        transform: translateY(50%);
        flex: 1;
        content: '';
    }
    .seprator-content {
        font-size: $size-12;
        color: $gray-5;
        padding: 0 0.5rem;
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

.strike-text {
    color: $gray-5 !important;
    text-decoration: line-through;
}

.page-header {
    @include flex-combinator(row, wrap, center, space-between);
    @include inline-flex;
}

.mat-drawer-container {
    &.countable-drawer {
        .mat-drawer-backdrop {
            display: none;
        }
    }
}
.fixed-width-container {
    @include flex-combinator(column, wrap);
    margin: 0 auto;
    padding: 0 $size-24;
    max-width: 100%;
    @media (max-width: 1366px) {
      padding: 0 $size-16;
    }
    @media (max-width: 992px) {
      padding: 0;
    }
}

body {
    .vertical-items,
    .flexbox-row {
        width: 100%;
        @include flex-combinator(row, nowrap, center);
        gap: $size-20;
        >* {
            width: auto;
        }

    }
    .horizontal-items,
    .flexbox-column {
        width: 100%;
        @include flex-combinator(column, nowrap);
        gap: $size-20;
    }

    .overflow-auto {
        overflow: auto !important;
    }
}

.disabled {
    pointer-events: none !important;
    opacity: 0.7 !important;
}
.disabled-view {
    opacity: 0.5;
    .item-list-content {
        pointer-events: none !important;
    }
}

.hover-action {
    position: relative;
    .hover-action-item {
        visibility: hidden;
        transition: 0s;
    }
    &:hover {
        .hover-action-item {
            visibility: visible;
            transition: 0s;
        }
    }
}
.cursor-default {
    cursor: default !important;
}

.dragCursor,
.dragable-icon,
.cursor-grab {
    cursor: grab !important;
}
.cdk-drag {
    // cursor: grab;
    &.cdk-drag-preview {
        width: 100%;
        border: $size-1 solid $primary !important;
        background-color: $primary-1;
        border-radius: $size-8;
        overflow: hidden;
        td {
            border: 0 !important;
            background-color: $primary-1;
        }
    }
}

.cdk-drop-list-dragging {
    .cdk-drag:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
}

.mat-ink-bar-none {
    > mat-tab-header {
        .mat-ink-bar,
        .mdc-tab-indicator {
            display: none !important;
        }
    }
}

.qb-icon {
  background-image: url('../../images/ui-uplift-icons/logos/quickbooks-brand.svg');
  width: 95px;
  height: 24px;
}

app-root {
  width: 100vw;
}

notes-to-fs-preview {
  display: block;
  height: 100%;
  overflow: auto;
}

app-teammembersdashboard,
app-planning,
app-firm-completion,
app-trial-balance-ls-grouping,
app-user-teamaccess,
app-daily-loads,
ntr-dashboard,
app-completion,
app-sign-offs,
app-member-team-list,
procedures,
app-worksheet-procedures,
engagements-list-grid,
app-compilation-report,
app-client-firm-settings,
app-accounting,
app-client-integrations,
app-client-settings,
app-client-final-report,
app-client-engagement-doc-repository,
app-client-engagement,
app-review-engagement,
app-materiality,
trailbalance-details,
app-nopegpact,
app-create-engagement,
app-client-engagement-info,
app-engagement-scope,
app-client-engagement-documents,
app-engagement-letters,
app-client-engagement-details,
app-member-team-add,
app-user-setting,
app-client,
app-templates,
app-reports,
app-privacy-security,
app-templates-dashboard,
app-independence,
client-acceptance,
app-completion-sign-off,
app-final-review,
app-knowledge-clients-business,
client-onboarding-planning,
engagement-letter,
engagement-details,
engagements-list,
app-engagement-letter-templates,
app-financial-statements,
app-review-checklists,
app-financial-statements,
app-worksheets-materiality,
app-worksheets-engagement-scope,
app-worksheets-completion,
app-whats-new,
app-template-singlepage-view,
app-file-preview,
app-document-preview,
app-import-zero-accounts,
app-import-zero-account {
    height: 100%;
    width: 100%;
    overflow: hidden;
    flex: 1;

    @media (max-width: 991px) {
      overflow: visible;
    }
}

app-final-fs-package,
app-user-profile {
  @include flex-combinator(column, nowrap);
    width: 100%;
    height: 100%;
    flex: 1;
}

balance-sheet-preview,
income-sheet-preview,
statement-cash-flows,
stockholder-equity-preview,
app-cover-page,
app-statement-of-retained-earning,
app-table-of-contents,
stockholder-equity-preview {
    height: 100%;
    width: 100%;
    overflow: auto;
}
income-sheet-preview {
  display: block;
}
app-templates-dashboard {
    height: 100vh;
}

app-worksheets-procedures,
engagements-list-grid,
app-user-export-data {
    height: 100%;
}
app-member-team-add {
    @include flex-combinator(column, nowrap);
}
app-template-paginated-view {
    width: 100%;
    //height: 100%;
    //overflow: hidden;
}
app-ai-preview,
app-ai-bot-window {
  @include sequre(100%);
  @include flex-combinator(column, nowrap, center, center);
}

//app-header-image,
//app-footer-image {
//  height: 100%;
//  width: 100%;
//}
body {
  app-ai-bot-window{
    background: transparent !important;
  }
}

body {
    .mat-tooltip,
    .mat-mdc-tooltip-panel {
        background-color: transparent;
        padding: 0;
        .mdc-tooltip,
        .mdc-tooltip__surface {
          padding: $size-4;
          background-color: $gray-9;
          color: $white;
          font-family: $font-primary;
          font-size: $size-12;
          font-weight: $regular;
          line-height: $size-18;
          border-radius: $size-8;
        }
    }
}


.client-info-block {
    .client-detail {
      @include flex-combinator(row, wrap, flex-start, initial);
      .client-detail-item {
        margin-bottom: 1.25rem;
        width: 180px;

        &:not(:last-child) {
          margin-right: 0.9375rem;
        }
      }
    }
  }

  .cards-group {
    .card.image-card {
      flex-basis: calc(50% - $size-12);
      &:hover {
        border-color: $primary;
      }
      .mat-radio-button,
      .mat-mdc-radio-button {
          .mat-radio-label {
              padding: $size-8;
            }
            .mdc-form-field {
              height: 100%;
              padding: $size-8;
              .mdc-label {
                height: 100%;
              }
            }
        }
    }
  }


    // notes-to-fs-preview {
    //   .minimalistBlackNotes {
    //     width: 100%;
    //   }
    // }
  .notes-container-aside-body {
    .minimalistBlackNotes {
      >tbody >tr >td {
        padding-right: 15px;
      }
    }
  }

.q-num {
  width: 30px;
  min-width: 30px;
}

//adding new css for html formatting of objective textaarea
.textarea-cls{
    min-width: 670px;
    height: auto;
    min-height: 65px;
    border: none;
    overflow-y: auto;
    resize: inherit;
    background-color: transparent;
    outline: none;
    display: inline-table;
    height: -webkit-fill-available;
    @media (max-width: 1500px) {
        min-width: 550px;
         max-width: 100%;
      }
      @media (max-width: 767px) {
        min-width: auto;
        max-width: 100%;
        width: 100%;
        display: block;

      }
    &.height-increase {
      height: 190px !important;
    }
  }
  textarea.textarea-cls:focus-visible{
    outline: none;
  }


  .disabled-mat-field{
    background-color: #F8F8FA;
    color: #778599;
    pointer-events: none;
}

.bot-response-body {
  mat-form-field {
    .mat-mdc-form-field-infix {
      label {
        @include flex-combinator(flex, row, nowrap);
        mat-label {
          white-space: normal;
        }
      }
    }
  }
}

body {
  .bw-4 {
    border-width: $size-4 !important;
  }
}

@media (max-width:991px) {
  app-cover-page{
    overflow: hidden;
  }
}


  .extension-img,
  .extension-icon {
    max-width:16px;
    max-height:16px;
  }



.count-card{
  background-color: $error-lighter !important;
  border-color:$error-3 !important;
}


div#cashflow-preview > div {
  overflow: initial !important;
}


.tb-check-button {
  .tb-check-icon {
    color: $error;
  }

  &:disabled {
    .tb-check-icon {
      color: $dark;
    }
  }
}
