.badge {
    border-color: transparent;
    background-color: transparent;
    padding: $size-10;
    padding: $size-10;
    font-family: $font-primary;
    font-weight: $medium;
    font-size: $size-12;
    font-weight: $medium;
    font-size: $size-12;
    line-height: 1 !important;
    border-radius: 0.375rem;
    @include flex-combinator(row, nowrap, center, center);
    @include inline-flex;
    gap: $size-8;

    &:empty {
        @include inline-flex;
    }

    .mat-button {
        line-height: 1;

        .mat-button-wrapper {
            line-height: 1;
        }
    }


    &-border {
        border: $size-1 solid transparent;
    }

    &-pills {
        border-radius: $size-16;
    }

    &-rounded {
        border-radius: $size-8 !important;
    }

    &-small {
        padding: $size-6 $size-10;
    }

    &-primary,
    &-In,
    &.inprogress,
    &.invite-now {
        background-color: $primary-1 !important;
        color: $primary-darker !important;
        border-color: $primary !important;
    }


    &-dark {
        background-color: transparent;
        color: $dark;
        border-color: $dark !important;
    }

    &-light {
        background-color: transparent;
        color: $dark;
        border-color: $gray-2 !important;
    }

    &-warning,
    &-New,
    &.new,
    &.invited {
        background-color: $warning-lighter;
        color: $warning-darker;
        border-color: $warning !important;
    }

    &-warning-light {
        background-color: transparent;
        color: $warning-dark;
        border-color: $warning-dark !important;
    }

    &-up-red{
        background-color:$error-50;
        color:$gray-10;
        border-color: $up-red !important;
    }

    &-tussock,
    &-checklists,
    &[class*="hecklist"] {
        background-color: $tussock-1;
        color: $tussock;
        border-color: $tussock !important;

        .mat-mdc-select-value-text,
        .mat-select-value-text {
            color: $tussock;
            font-size: $size-12;
            font-weight: $medium;
        }
    }

    &-secondary,
    &-folder,
    &[class*="older"] {
        background-color: $secondary-1;
        color: $secondary-2;
        border-color: $secondary-dark !important;

        .mat-mdc-select-value-text,
        .mat-select-value-text {
            color: $secondary-2;
            font-size: $size-12;
            font-weight: $medium;
        }
    }

    &-purple,
    &-compilation,
    &-letters,
    &[class*="etter"],
    &[class*="eopen"] {
        background-color: $purple-1;
        color: $purple;
        border-color: $purple !important;

        .mat-mdc-select-value-text,
        .mat-select-value-text {
            color: $purple;
            font-size: $size-12;
            font-weight: $medium;
        }
    }

    &-yellow,
    &-module,
    &[class*="odule"] {
        background-color: $yellow-1;
        color: $yellow;
        border-color: $yellow !important;

        .mat-mdc-select-value-text,
        .mat-select-value-text {
            color: $yellow;
            font-size: $size-12;
            font-weight: $medium;
        }
    }

    &-blue-astro,
    &-engagements,
    &[class*="ngagement"] {
        background-color: $blue-astro-1;
        color: $blue-astro;
        border-color: $blue-astro !important;

        .mat-mdc-select-value-text,
        .mat-select-value-text {
            color: $blue-astro;
            font-size: $size-12;
            font-weight: $medium;
        }
    }

    &.archived,
    &.Archived,
    &-Archived,
    &-archived,
    &-gray {
        background-color: $gray-1;
        color: $gray-7;

        &[class*="border"] {
            border-color: $gray-8;
        }
    }

    &.active,
    &-success,
    &[class*="ompleted"],
    &-notes,
    &[class*="ote"],
    &.accepted {
        background-color: $success-1;
        color: $success-darker;

        &[class*="border"] {
            border-color: $success-darker;
        }

        .mat-mdc-select-value-text,
        .mat-select-value-text {
            color: $success;
            font-size: $size-12;
            font-weight: $medium;
        }
    }

    &-pink,
    &-reports,
    &[class*="eport"] {
        background-color: $pink-lighter;
        color: $pink-darker;
        border-color: $pink-darker !important;

        .mat-mdc-select-value-text,
        .mat-select-value-text {
            color: $pink-dark;
            font-size: $size-12;
            font-weight: $medium;
        }
    }

    &-blue-moody,
    &-worksheets,
    &[class*="orksheet"] {
        background-color: $blue-moody-1;
        color: $blue-moody;
        border-color: $blue-moody !important;

        .mat-mdc-select-value-text,
        .mat-select-value-text {
            color: $blue-moody;
        }
    }

    &-blue-light {
        background-color: $secondary-lighter;
        color: $blue-light;
        border-color: $blue-light !important;
    }

    &-error,
    &-Failed,
    &-Not {
        background-color: $error-1;
        color: $error-darker;
        border-color: $error !important;
    }

    &-tertiary-blue {
        background-color: $tertiary-blue-lighter;
        color: $tertiary-blue-7;
    }

    &-circle {
        border-radius: 50%;
        @include flex-combinator(row, nowrap, center, center);

        img {
            width: $size-16;
        }
    }

    // &-small {
    //   @include fixed-width($size-36);
    //   border-width: $size-4 !important;
    // }

    &-icon {
        &:before {
            content: '';
            background-image: url('../../images/icons/arrow-circle-up.svg');
            @include sequre(1rem);
            min-width: $size-16;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &[class*="hecklist"] {
            &:before {
                background-image: url('../../images/icons/checklist-icon.svg');
            }
        }

        &[class*="older"] {
            &:before {
                background-image: url('../../images/icons/folder-icon.svg');
            }
        }

        &[class*="etter"] {
            &:before {
                background-image: url('../../images/icons/letters-icon.svg');
            }
        }

        &[class*="odule"] {
            &:before {
                background-image: url('../../images/icons/module-icon.svg');
            }
        }

        &[class*="ngagement"] {
            &:before {
                background-image: url('../../images/icons/engagement-icon.svg');
            }
        }

        &[class*="eport"] {
            &:before {
                background-image: url('../../images/icons/reports-icon.svg');
            }
        }

        &[class*="ote"] {
            &:before {
                background-image: url('../../images/icons/notes-icon.svg');
            }
        }

        &[class*="orksheet"] {
            &:before {
                background-image: url('../../images/icons/worksheets-icon.svg');
            }
        }
    }

    .mat-select-trigger {
        height: auto !important;
    }

}


[class*="circle-"] {
    @include flex-combinator(row, nowrap, center, center);
    padding: 0;

    &[class*="icon-28"] {
        @include circle($size-32);
    }


    &[class*="secondary-dark"] {
        background-color: $secondary-hover;
        border: $size-4 solid $primary-1;
    }
}



.mat-badge {
    &.mat-badge-primary {
        .mat-badge-content {
            background-color: $primary;
            color: $white;
        }
    }

    &.mat-badge-error,
    &.mat-badge-red {
        .mat-badge-content {
            background-color: $error;
            color: $white;
        }
    }

    &.mat-badge-medium {
        .mat-badge-content {
            @include circle($size-18);
            line-height: $size-18;
            font-size: $size-10;
            z-index: 1;

        }

        &.mat-badge-overlap.mat-badge-after {
            .mat-badge-content {
                right: -10px;
            }
        }

        &.mat-badge-above {
            .mat-badge-content {
                top: -10px;
            }
        }
    }
}

.mat-badge-content {
    font-weight: $medium;
    font-family: $font-primary;
    z-index: 1;
}